#light-box{display:none; background-color: #000000; padding: 6px 6px 4px 6px; }

.lightboxBg{ background:url(../images/light_box_bg.png) repeat 0px 0px;  position: absolute; top: 0; left: 0; display: none; z-index: 100; }
.lightbox{ position: fixed; top: 50%; left: 50%; display: none; z-index: 101;}

.contact-close {
  height: 44px;
  position: absolute;
  right: -25px;
  top: -25px;
  width: 44px;
}

.contact-close{ cursor: pointer; }

.contact-close span {
  display: block;
  font-size: 38px;
  left: 23%;
  line-height: 22px;
}

.contact-close span{ cursor:pointer;
position:absolute; top:11%;
-webkit-transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
-moz-transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
-o-transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
-webkit-transform: rotate(0deg);
-moz-transform: rotate(0deg);
-ms-transform: rotate(0deg);
-o-transform: rotate(0deg);
transform: rotate(0deg);
color:#eb212e;
}

.contact-close:hover span{-webkit-transform:rotate(-360deg);-moz-transform:rotate(-360deg);-ms-transform:rotate(-360deg);-o-transform:rotate(-360deg);transform:rotate(-360deg);}

.light-box-header{ background-color: #000000; padding: 10px 5px; color: #ffffff; }
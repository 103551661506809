.customSelect { 
	position: relative;			
	font-size: 12px;
	font-family: "Lucida Grande", Arial, Helvetica, sans-serif; 
	float: left;
	margin-right: 10px;
}		
.customSelect .selectedValue {
  padding: 5px 10px;
  margin-bottom: 0;
  line-height: 1.2;
  color: #333333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);

  cursor: pointer;
  background-color: #f5f5f5;
  *background-color: #fff;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #bbbbbb;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #a2a2a2;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  *zoom: 1;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.customSelect .selectList {
	z-index: 100;
	position: absolute;
	left: -999em;
	top: -999em;
	
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;
}

.customSelect.select-open .selectList {
	left: 0 !important;
	top: auto !important;
}
.customSelect .selectList dl {
	background: #fff;	
}
.customSelect .selectList dl.defaultScrollbar {
	overflow-x: hidden;
	overflow-y: scroll;	
}
.customSelect dl, .customSelect dd {
	margin: 0;
}
.customSelect dd {
	zoom: 1;
	cursor: pointer;
}
.customSelect .disabled {
	color: #999;
	cursor: default;
}
.customSelect .selected {
	color: #ffffff;
	text-decoration: none;
	background-color: #0081c2;
	background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
	background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
	background-image: -o-linear-gradient(top, #0088cc, #0077b3);
	background-image: linear-gradient(to bottom, #0088cc, #0077b3);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}
.customSelect .selectList dd.hovered {
	background: #999;
	color: #fff;
}
.customSelect .selectList dd.disabled.hovered {
	background: none;
	color: #999;
}
.customSelect dt {
	font-weight: bold;
	font-style: italic;
	padding: 2px 10px 2px 6px;
}	
.customSelect dd {
	padding: 2px 10px 2px 8px;
} 
.hasJS select.custom {
	position: absolute;
	left: -999em;
}
.hasJS select.custom.use-default {
	position: absolute;
	left: auto;
	height: auto;
	width: 100%;
	appearance:none;
	-moz-appearance:none;
	-webkit-appearance:none;
	opacity: 0;
	/* prevents iOS from zooming in on field */
	font-size: 16px;
}
.customSelect.focused .selectedValue {
	box-shadow: 0 0 2px #00aedb;
}
.customSelect.disabled .selectedValue {
	border: 1px solid #ccc;
	color: #ccc;
	cursor: default;
}
.customSelect .caret {
	display: inline-block;
	width: 0;
	height: 0;
	vertical-align: top;
	border-top: 4px solid #000000;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	content: "";
}
.customSelect .selectValueWrap .caret {
	position: absolute;
	top: 12px;
	right: 10px;
	cursor: pointer;
}
.customSelect .jspPane {
	overflow-x: hidden;
}
.customSelect .jspVerticalBar {
	background: none;
	width: 8px;
}
.customSelect .jspHorizontalBar {
	height: 0;
	overflow: hidden;
}
.customSelect .jspTrack {
	background: none;
	right: 5px;
	border-radius: 5px;
}
.customSelect .jspDrag {
	border-radius: 5px;
	color: #ffffff;
	
	opacity: .5;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #363636;
	*background-color: #222222;
	background-image: -moz-linear-gradient(top, #444444, #222222);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444444), to(#222222));
	background-image: -webkit-linear-gradient(top, #444444, #222222);
	background-image: -o-linear-gradient(top, #444444, #222222);
	background-image: linear-gradient(to bottom, #444444, #222222);
	background-repeat: repeat-x;
	border-color: #222222 #222222 #000000;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff222222', GradientType=0);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.stylish-select {
    width: 100%;
    position: relative;
    z-index: 1
}

.stylish-select-left {
    position: absolute;
    z-index: 1;
    width: 100%;

    &:focus-visible {
        @include focus-outline();
    }
}

.stylish-select-right {
    @include font-medium();
    background-color: #fff;
    background-image: url(../images/selectArrow.png);
    background-repeat: no-repeat;
    background-position: right center;
    box-sizing: initial;
    color: #7f7f7f;
    font-size: 16px;
    height: 20px;
    line-height: 24px;
    padding: 10px 36px 10px 12px;
    overflow: hidden;
}

.event-search-result-wrapper .sortSel .stylish-select-right { /* New Events Page */
    font-size: 14px;
}


ul.listing {
    background: #fff;
    border: 2px solid #000;
    display: none;
    left: -2px;
    list-style-type: none;
    margin: 0;
    padding: 0px 0 0 0;
    position: absolute;
    top: 40px;
    width: 100%;
    z-index: 100;
    max-height: 150px;
    overflow: auto;
}

ul.listing li {
    @include font-medium();
    padding: 0px 10px;
    display: block;
    font-size: 16px;
    color: #666;
    line-height: 30px;
}

ul.listing li:hover, ul.listing li:focus {
    color: #ffffff;
    background: $color-purple;
    cursor: default;
}

.main {
    border: 0 solid #f00;
    height: 40px;
    position: relative;
    border: #000 solid 2px;
    z-index: 3;
}

.responsive-nav {
    background-color: $color-purple;
    bottom: 0;
    display: block;
    left: -320px;
    overflow-y: scroll;
    padding-top: 7px;
    position: fixed;
    top: 50px;
    width: 320px;
    z-index: 6000;
    visibility: hidden;

    @include respond-to('xs') {
        top: 60px;
    }
}
.responsive-nav .padding10 ul.nav-main-link > li {
  border-bottom: 0px solid #7b5193;
  position: relative;
}
.responsive-nav .padding10 ul.nav-main-link > li a {
  @include font-heading();
  color: $color-white;
  font-size: 24px;
  display: block;
  padding: 10px 0;
  text-transform: uppercase;
}
.responsive-nav .padding10 ul.nav-main-link > li a.expand {
  background: url(../images/responsive-nav-arrow.png) no-repeat 98% 26px;
}
.responsive-nav .padding10 ul.nav-main-link > li:nth-child(2) a {
  background: none;
}
.responsive-nav .close-wrapper {
  display: none;
}
.responsive-nav .padding10 ul.nav-main-link > li > .sub-menu {
  display: none;
  padding-bottom: 0 !important;
}
.responsive-nav .padding10 ul.nav-main-link > li > .sub-menu ul.level-1 li {
  border-bottom: 1px solid #7b5193;
  margin-bottom: 0 !important;
}
.responsive-nav
  .padding10
  ul.nav-main-link
  > li
  > .sub-menu
  ul.level-1
  li.last {
  border: 0px solid #7b5193;
}
.responsive-nav .padding10 ul.nav-main-link > li > .sub-menu ul.level-1 li a {
  background: none;
  color: #fff;
  font-size: 14px;
  display: block;
  padding: 12px 0px;
}
.responsive-nav
  .padding10
  ul.nav-main-link
  > li
  > .sub-menu
  ul.level-1
  li
  a:hover {
  background: #ffffff;
  color: $color-black;
}
.responsive-nav
  .padding10
  ul.nav-main-link
  > li
  > .sub-menu
  ul.level-1
  li
  > ul.level-2
  li {
  border: none;
}
.responsive-nav .padding10 ul.nav-main-link > li > .sub-menu .col-2 {
  display: none;
}
.responsive-nav .padding10 ul.nav-main-link > li > .sub-menu .search-mblock-bg {
  display: none;
}
.responsive-nav .padding10 ul.nav-main-link > li > .sub-menu .view-all-link {
  display: none;
}
.responsive-nav .padding-lr {
  padding: 0;
}
.responsive-nav .padding-lr .col-1 ul.level-1 {
  border-top: 1px solid #7b5193;
}
.responsive-nav
  .padding10
  ul.nav-main-link
  > li
  > .sub-menu
  ul.level-1
  li.selectedMenu {
  margin-bottom: 0 !important;
}
.responsive-nav > .padding10 {
  padding: 10px 0px;
}
.responsive-nav .padding10 ul.nav-main-link > li > a {
  margin: 0px 12px;
}
.responsive-nav .padding10 ul.nav-main-link > li > .sub-menu ul.level-1 li > a {
  padding: 12px;
}
.mobile-breadcrumb {
  display: none;
}
.desktop-breadcrumb {
  display: block;
}

/* Start Global Language Selector */
#language-select-container {
  background-color: $color-purple;
  padding: 33px 0;
  display: none;
}

.language-select-toggle-container {
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  line-height: 25px;
  float: right;
  padding-top: 6.5px;
}

.language-select-toggle-container .language-select-toggle-label {
	@include font-semibold();
  font-size: 14px;
  color: #464646;
}

.language-select-toggle-container .language-select-toggle-icon {
  background: url("../images/globe-icon-desktop.png");
  height: 25px;
  width: 25px;
  margin-left: 8px;
  background-size: contain;
}

.language-select-toggle-container:hover .language-select-toggle-label {
  color: $color-purple;
}

.language-select-toggle-container:hover .language-select-toggle-icon {
  background: url("../images/globe-icon-desktop_hover.png");
  background-size: contain;
}

.language-select-options {
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.language-select-options > div {
  font-size: 16px;
  color: #e3e3e3;
  text-align: center;
}

.language-select-options > div + div {
  margin-left: 40px;
}

.language-select-options > div.icon-close-container {
  width: 24px;
  align-self: flex-end;
}

.language-select-options > div div.icon-close {
  background: url("../images/icon-menu-close.png") no-repeat 0 0;
  cursor: pointer;
  height: 24px;
  width: 24px;
  float: right;
}

.language-select-options > div a {
	@include font-semibold();
  width: 100%;
  color: #e3e3e3;
  position: relative;
  transition: color ease-in-out 0.3s;
  display: inline-block;
}

.language-select-options > div a:after {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -4px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 0%;
  background: #fff;
  transition: width ease-in-out 0.3s;
}

.language-select-options > div a.active {
  color: #fff;
}

.language-select-options > div a.active::after {
  width: 100%;
}

.language-select-options > div a:hover {
  color: #fff;
}

.language-select-options > div a:hover::after {
  width: 100%;
}
/* End Global Language Selector */

/* Start In Page Language Selector  */
.inpage-language-selector-container {
  padding-bottom: 20px;
}

.language-selector {
  font-size: 0px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.language-selector a {
    @include font-semibold();
    font-size: 16px;
    color: $color-dark-grey;
}

.language-selector a:hover {
  color: $color-purple;
}

.language-selector__separator:not(:last-of-type)::after {
    content: "";
    border-right: 2px solid $color-dark-grey;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 12px;
}
/* End In Page Language Selector */

/* Start In Page Social Share */
.social-media-link-ls__container {
  display: none;
}

.blog-share .st-custom-button[data-network="linkedin"],
.blog-share .st-custom-button[data-network="facebook"] {
  margin-left: 12px;
}

.sts-custom-button, .social-media-link-ls .st-custom-button-pdf,
.social-media-link-ls__container.social-media-link-ls__container--open
  .st-custom-button-pdf,
.social-media-link-ls__container .st-custom-button-pdf {
  background: url(/-/media/images/media-icon/social-media-icons.ashx) no-repeat
    0 0;
  display: block;
  float: left;
  height: 30px;
  overflow: hidden;
  text-indent: -99999px;
  width: 30px;
  background-position: 0 -182px;
  cursor: pointer;
}

.st-custom-button-pdf:focus,
.st-custom-button-pdf:hover,
.social-media-link-ls__container .st-custom-button-pdf:focus,
.social-media-link-ls__container .st-custom-button-pdf:hover {
    background-position: -43px -182px;
}

.social-media-link-ls {
  position: absolute;
  width: 30px;
  margin-left: 20px;
}

@media only screen and (max-width: 768px) {
    .social-media-link-ls {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 768px) {
    .layout-mobile
    .person-detail-mob ~ .social-media-link-ls.social-media-link-ls.social-media-link-ls,
    .social-media-link-ls.social-media-link-ls.social-media-link-ls,
    .bioSideBar .social-media-link-ls {
        display: none;
    }

    .layout-mobile
    .person-detail-mob ~ .social-media-link-ls.social-media-link-ls--mobile.social-media-link-ls--mobile.social-media-link-ls--mobile,
    .social-media-link-ls.social-media-link-ls--mobile.social-media-link-ls--mobile.social-media-link-ls--mobile {
        display: block;
        position: fixed;
        right: 5px;
        width: 40px;
        height: 40px;
        top: calc(100% - 90px);
        background: url("/Contents/images/social-share-icon.png");
        background-size: cover;
        cursor: pointer;
        z-index: 5;
        filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.2));
    }

    .layout-mobile
    .person-detail-mob ~ .social-media-link-ls.social-media-link-ls--mobile.social-media-link-ls--mobile.social-media-link-ls--mobile-open,
    .social-media-link-ls.social-media-link-ls--mobile.social-media-link-ls--mobile.social-media-link-ls--mobile-open {
        /* background: url('/Contents/images/close-icon.gif'); */
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
    }

    .social-media-link-ls__container {
        position: fixed;
        /* overflow: hidden; */
        padding: 10px;
        right: 0;
        top: calc(100% - 335px);
        display: flex;
        cursor: pointer;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        flex-direction: column;
        background-color: #464646;
        z-index: 5;
        filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.2));
    }

    .social-media-link-ls__container--open {
        overflow: visible;
        /* display: flex; */
        transform: translateX(0);
    }

    .social-media-link-ls--mobile {
        left: auto !important;
        margin-left: 10px;
        margin-top: -45px;
    }

    .layout-mobile
    .person-detail-mob ~ .social-media-link-ls
    .st-custom-button[data-network="email"],
    .social-media-link-ls .st-custom-button[data-network="email"],
    .layout-mobile
    .person-detail-mob ~ .social-media-link-ls
    .st-custom-button[data-network="facebook"],
    .social-media-link-ls .st-custom-button[data-network="facebook"],
    .layout-mobile
    .person-detail-mob ~ .social-media-link-ls
    .st-custom-button[data-network="linkedin"],
    .social-media-link-ls .st-custom-button[data-network="linkedin"],
    .layout-mobile
    .person-detail-mob ~ .social-media-link-ls
    .st-custom-button[data-network="twitter"],
    .social-media-link-ls .st-custom-button[data-network="twitter"],
    .layout-mobile
    .person-detail-mob ~ .social-media-link-ls
    .sts-custom-button-email,
    .social-media-link-ls .sts-custom-button-email,
    .social-media-link-ls .st-custom-button-pdf,
    .social-media-link-ls .st-custom-button a,
    .social-media-link-ls .sts-custom-button a {
        margin-top: 0;
        float: none;
        display: block;
    }

    .social-media-link-ls__container .st-custom-button-close {
        font-family: "icons";
        font-size: 30px;
        color: #c8c8c8;
        display: inline-block;
        padding-top: 20px;

        &:focus,
        &:hover {
            color: $color-black;
        }
    }

    .social-media-link-ls__container .st-custom-button-close::before {
        content: "\e919";
    }
}
/* End In Page Social Share */

/* Start Person Disclaimers */
.person-disclaimer-container {
  padding-bottom: 29px;
}

.person-disclaimer-container .person-disclaimer {
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
  color: #414042;
  margin-bottom: 28px;
}

.person-disclaimer-container .person-disclaimer:last-child {
  margin-bottom: 0;
}
/* End Person Disclaimers */

/* Prevent Scroll Anchoring */
body {
  overflow-anchor: none;
}
/* End Scroll Anchoring */

@media only screen and (max-width: 956px) {
  .container {
    width: 100%;
  }
  .no-padding {
    padding: 0 10px;
  }
  .customSelect {
    width: 100% !important;
  }
  .sticky-header .logo-wrapper {
    left: 50%;
    margin: 0 auto;
    margin-left: -100px;
    top: 12px;
    width: 200px;
  }
  .overlap-ban {
    width: 100%;

    h1 {
      margin-left: 1%;
    }
  }

  .no-ban + .overlap-ban {
    width: 98%;

    h1 {
      margin-left: 0;
    }
  }

  #nav-wrapper ul.nav-main-link {
    margin-left: 0;
  }
  #nav-wrapper ul.nav-main-link > li {
    float: none;
    display: inline-block;
  }
  /*ourpeople*/
  .peopSearSect,
  .people-search-result-wrapper {
    width: 90%;
  }
  #select-custom4 {
    width: 150px !important;
  }
  .people-search-result-wrapper .filter-search-head .right {
    float: right;
    margin-top: 9px;
  }

  .careerBox {
    padding: 40px;
  }
  .careerBox .categoryList {
    width: 91%;
  }
  .careerBox label {
    margin-right: 7px;
  }
  .careerBox .alumniDropdown .categoryList {
    width: 72%;
  }
  #careerOwlSlider.owl-theme .owl-controls {
    left: 35px;
  }

  .landing-page .layout-mobile .top-level-cont .top-level-two-col .col-l {
    margin-right: 0;
    width: 100%;
  }
  .landing-page .layout-mobile #owl-example-outer-contaier {
    margin-left: 8.5%;
    margin-right: 8.5%;
  }
  .landing-page .layout-mobile .top-level-cont {
    border-bottom: 0px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .landing-page .layout-mobile .top-level-cont .top-level-two-col .col-l p {
    font-size: 23px;
    line-height: 25px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  #search-wrapper .col-1 {
    width: 79%;
  }

  .main-heading h1 {
      padding-right: 10%;
  }

  #mapContainer .breadcrumb-overlay .main-heading h1.map-head {
      margin-left: 16px;
  }

  #mapContainer .breadcrumb-overlay .breadcrumbs {
    margin-left: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .no-padding {
    padding: 0 10px;
  }
  .customSelect {
    width: 100% !important;
  }
  .sticky-header .logo-wrapper {
    left: 50%;
    top: 17px;
  }
  .login-lang-wrapper ul li {
    margin-left: 15px;
  }
  #search-wrapper .col-1 {
    width: 76%;
  }
  #search-wrapper .col-2 {
    width: 19%;
  }
  #nav-wrapper ul.nav-main-link > li {
    margin: 0 19px;
  }
  .collapse-box {
    margin: 0 6.451612903225806%;
  }
  .inpage-language-selector-container {
    padding-bottom: 0;
  }
  .person-heading {
    margin-left: 0;
    width: 66%;
  }
  .person-heading > div {
    float: left;
    margin-right: 2%;
    padding: 0 6.45161% 0 calc(6.45161% + 10px);
    width: 66%;
  }
  .col-l > .collapse-box {
    padding-top: 5px;
  }
  .collapse-box .collapsible-content h1 {
    font-size: 40px;
  }
  .collapse-expand-a .block h2 {
    font-size: 24px;
  }
  .bread-crumb {
    padding: 13px 0 12px;
  }
  .owl-carousel-people h2 {
    font-size: 24px;
  }
  .collapse-expand-a {
    margin-top: 60px;
  }
  .collapse-box {
    margin: 0 6.45161%;
  }
  .hero-ban-home p {
    margin-left: 15px;
  }
  .collapse-expand-a {
    margin-top: 0px;
  }
  .social-media-link-ls {
    left: -36px;
    display: inline-block;
    margin-left: -10px;
    right: 0;
  }
  .sec-level-sub-menu {
    width: 390px;
  }

  .main-heading h1 {
    margin-left: 16px;
  }
  #mapContainer .main-heading h1 {
    margin-left: 0px;
  }
  .hero-ban-home {
    background: url("../images/home-page-banner.jpg") no-repeat scroll right
      bottom;
    background-size: 60%;
  }
  .subsection-list h2 {
    padding-right: 49px;
  }
  .pub-main-head {
    display: none;
  }
  .form-publication-section {
    padding-top: 0px;
  }
  .filter-search-head .left,
  .result-found-res p {
    line-height: 35px;
  }
  .filter-search-head .mid {
    line-height: 32px;
    margin-left: 11%;
    float: right;
  }
  .people-search-result-wrapper .filter-search-head .right {
    margin-top: 0px;
  }
  .peopSearSect {
    margin: 0 auto 35px;
  }
  .search-media-form {
    margin: 0 8.5% 35px;
  }
  #start_date-search,
  #end_date-search {
    width: 87%;
  }
  .people-search-result-inner-wraper ul li div.vcard {
    width: 23%;
  }
  .people-search-result-inner-wraper ul li div.details {
    width: 26%;
  }
  .right-block,
  .left-block {
    float: left;
    width: 100%;
  }
  .layout-desktop
    .form-publication-section
    input[type="text"]#start_date-search,
  .layout-desktop .form-publication-section input[type="text"]#end_date-search {
    width: 89%;
  }
  .search-media-form input[type="text"]#start_date-search,
  .search-media-form input[type="text"]#end_date-search {
    width: 85%;
  }
  .filter-search-head .right {
    float: left !important;
  }
  .filter-search-head .right-most {
    float: right;
  }
  .pub-search-area > .filter-search-head .right .sortSel {
    width: 134px;
  }
  #divContactUs .oneHalf .main {
    width: 95.5%;
  }
  .layout-mobile .related-source h1 {
	@include font-heading();
    background: url(../images/accordian-arrow-a.png) no-repeat 0px 0px;
    cursor: pointer;
    font-size: 24px;
    line-height: 31px;
    padding-left: 45px;
    text-transform: uppercase;
  }
  .layout-mobile .related-source h1.expand {
    background-position: 0 -110px;
  }
  .careerBox h2 {
    padding-right: 15px;
  }
  #nav-wrapper ul.nav-main-link > li > .sub-menu {
    padding-bottom: 31px;
  }
  .close-wrapper {
    padding: 7px 0 0px 0;
  }
  #nav-wrapper
    ul.nav-main-link
    > li
    > .sub-menu
    .col-1
    ul.level-1
    > li
    ul.level-2 {
    margin-top: 4px;
  }
  #nav-wrapper
    ul.nav-main-link
    > li
    > .sub-menu
    .col-1
    ul.level-1
    > li
    ul.level-2
    > li {
    margin-bottom: 1px;
  }
  #nav-wrapper ul.nav-main-link > li > .sub-menu .col-1 ul.level-1 > li {
    margin-bottom: 17px;
  }
  /*.site-map{ padding-left: 20px;padding-right: 20px; }*/
  .white-container {
    padding-top: 55px;
  }
}

@media only screen and (max-width: 320px) {
  .pub-search-area .filter-search-head .r-left {
    width: 123px !important;
  }
  .pub-search-area > .filter-search-head .right .sortSel {
    width: 124px !important;
  }

  .social-media-link-ls {
    left: -36px;
    display: inline-block;
    left: auto !important;
    right: 0;
    width: auto;
    position: absolute;
    margin-left: -12px;
  }
}

@media only screen and (max-width: 414px) {
  .social-media-link-ls {
    left: -36px;
    display: inline-block;
    left: auto !important;
    right: 0;
    width: auto;
    position: absolute;
    margin-left: -12px;
  }
}

@media only screen and (max-width: 411px) and (max-height: 823px) {
  .social-media-link-ls {
    left: -36px;
    display: inline-block;
    left: auto !important;
    right: 0;
    width: auto;
    position: absolute;
    margin-left: -12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .hero-ban-home {
    background-size: 60%;
  }
}

@media only screen and (max-width: 834px) {
    .main-heading h1 {
        font-size: 48px;
    }
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .video-block > img {
    height: 169px;
  }
  .map-box {
    top: 56%;
  }
  #individual-map {
    height: 170px;
    width: 170px;
  }
}

@media only screen and (max-width: 640px) {
  .topBar {
    float: right;
  }

  .language-select-toggle-container {
    padding-top: 0px;
  }

  .language-select-toggle-container .language-select-toggle-icon {
    height: 22px;
    width: 22px;
    background: url(../images/top-icon-mobile.png) no-repeat 0 0;
  }

  .language-select-toggle-container:hover .language-select-toggle-icon {
    background: url(../images/top-icon-mobile.png) no-repeat 0 0;
  }

  .language-select-toggle-container .language-select-toggle-label {
    display: none;
  }

  .language-select-toggle-container.language-btn-close
    .language-select-toggle-icon {
    background: url(../images/top-icon-mobile.png) no-repeat 0 -152px !important;
  }

  .language-select-options {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .language-select-options
    > div:not(.icon-close-container)
    + div:not(.icon-close-container) {
    margin-left: 0px;
  }
  .language-select-options > div {
    width: 49%;
    margin-top: 20px;
  }
  .language-select-options > div:nth-child(-n + 2) {
    margin-top: 0px;
  }
  .language-select-options > div.icon-close-container {
    display: none;
  }

  /*Header*/
  header#top-header {
    min-height: 50px;
  }
  header#top-header {
    position: fixed;
  }
  header#top-header .headerCont {
    padding: 13px 0 3px;
  }
  .sticky-header .logo-wrapper,
  header .logo-wrapper {
    float: left;
    left: 0;
    margin: 0;
    position: inherit;
    top: 0;
    clear: left;
    width: 200px;
  }
  .sticky-header .logo-wrapper img,
  header .logo-wrapper img {
    width: 100%;
  }
  #responsiv-nav-btn {
    display: block;
  }
  header #nav-wrapper {
    display: none;
  }
  .global-search {
    float: right;
    margin-left: 10px;
  }
  .global-search input.btn-global-search {
    background: url(../images/top-icon-mobile.png) no-repeat 0px -53px;
    border: medium none;
    cursor: pointer;
    height: 22px;
    padding: 0;
    text-indent: -999999px;
    width: 22px;
  }
  .global-search input.btn-global-search.btn-global-search-close {
    background: url(../images/top-icon-mobile.png) no-repeat 0 -152px !important;
  }
  .sticky-header #responsiv-nav-btn,
  header #responsiv-nav-btn {
    float: right;
    margin-right: 0;
    margin-left: 10px;
  }
  .sticky-header #responsiv-nav-btn img,
  header #responsiv-nav-btn img {
    display: none;
  }
  .sticky-header #responsiv-nav-btn,
  header #responsiv-nav-btn {
    border: medium none;
    cursor: pointer;
    height: 22px;
    padding: 0;
    text-indent: -999999px;
    width: 22px;
  }
  .sticky-header #responsiv-nav-btn,
  header #responsiv-nav-btn.res-nav-icon {
    background: url(../images/top-icon-mobile.png) no-repeat 0px -102px !important;
  }
  .sticky-header #responsiv-nav-btn,
  header #responsiv-nav-btn.res-nav-icon-close {
    background: url(../images/top-icon-mobile.png) no-repeat 0 -152px !important;
  }
  #language-btn.language-btn-close {
    background: url(../images/top-icon-mobile.png) no-repeat 0 -152px !important;
  }
  .header-outer {
    min-height: inherit;
    height: 50px;
  }
  #language-select-container,
  #search-wrapper {
    position: absolute;
    top: 52px;
    width: 100%;
  }
  .sticky-header .login-lang-wrapper {
    margin-top: 0;
  }
  #divContactUs input[type="text"],
  #divContactUs select {
    width: 96%;
  }
  #divContactUs .oneHalf .main {
    width: 94%;
  }
  #divContactUs textarea {
    width: 98%;
  }
  .filter-search-head .mid {
    float: right;
  }
  .right-block,
  .left-block {
    float: left;
    width: 100%;
  }
  .filter-search-head .r-left {
    float: left !important;
  }
  .filter-search-head .right-most {
    float: right;
  }
  .pub-search-area .result-found {
    padding-bottom: 0px;
    font-size: 16px;
  }
  .pub-search-area .filter-search-head .r-left {
    width: 151px;
  }
  .pub-search-area .filter-search-head .right-most,
  .pub-search-area .filter-search-head .right-most .sortSel {
    width: 123px;
  }
  .global-search-sorting {
    width: 180px;
  }
  .alphaSearch.owl-theme .owl-controls {
    display: block;
  }
  .pubHiddenArea .publicationTypes label {
    width: 83%;
  }
  .publicSelectAll label {
    width: 72%;
  }
  .banner-content h2 {
    font-size: 18px;
    line-height: 23px;  
  }
  .no-result-found {
    margin-bottom: 15px;
  }
  .careerBox .categoryList {
    width: 86%;
  }
  .careerBox .alumniDropdown .categoryList {
    width: 64%;
  }
  #divContactUs .captField input[type="text"] {
    width: 67%;
  }
  .captField span.captcha_field {
    width: 27%;
  }
  .mobile-breadcrumb {
    display: block;
  }
  .desktop-breadcrumb {
    display: none;
  }
  .careerBox .categoryList input {
    width: 90%;
  }
  .careerBox .categoryList .main {
    width: 100%;
  }

  .inner-wrapper {
    display: none;
  }
  .main-heading h1 {
    font-size: 32px;
    padding-right: 16px;
  }
  .mobile-breadcrumb {
    width: 100%;
  }
  .breadcrumsection > .mobile-breadcrumb {
    width: 80%;
  }
  
  #individual-map {
    height: 142px;
    width: 142px;
  }
  #search-wrapper .col-1 {
    width: 72%;
  }
  .layout-desktop
    .form-publication-section
    input[type="text"]#start_date-search,
  .layout-desktop .form-publication-section input[type="text"]#end_date-search {
    width: 87%;
  }
  .search-media-form input[type="text"]#start_date-search,
  .search-media-form input[type="text"]#end_date-search {
    width: 82%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 726px) {
  #divContactUs input[type="text"],
  #divContactUs select {
    width: 96%;
  }
  #divContactUs .oneHalf .main {
    width: 95%;
  }
  #divContactUs textarea {
    float: left;
    width: 98%;
  }
  #nav-wrapper ul.nav-main-link > li {
    margin: 0 13px;
  }
  .banner-content h2 {
    margin-top: 37px;
  }
  #search-wrapper .col-1 {
    width: 72%;
  }
  .careerBox .categoryList {
    width: 90%;
  }
  .careerBox .alumniDropdown .categoryList {
    width: 69%;
  }
  .careerBox .categoryList input {
    width: 91%;
  }
  .careerBox .categoryList .main {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 667px) {
  .layout-mobile .related-source h1 {
	@include font-heading();
    background: url(../images/accordian-arrow-a.png) no-repeat 0px 0px;
    cursor: pointer;
    font-size: 24px;
    line-height: 31px;
    padding-left: 45px;
    text-transform: uppercase;
  }
  .layout-mobile .related-source h1.expand {
    background-position: 0 -110px;
  }
  /*.form-publication-section input[type="text"]#start_date-search, .form-publication-section input[type="text"]#end_date-search{ width: 87%; }*/
}

@media only screen and (max-width: 610px) {
  #globalsearchresult1 > ul#divPeopleListing li div.vcard p.v-card a {
    line-height: 13px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 545px) {
  #divContactUs input[type="text"],
  #divContactUs select {
    width: 95%;
  }
  #divContactUs .oneHalf .main {
    width: 93%;
  }
  #divContactUs textarea {
    width: 97%;
  }
  .careerBox .alumniDropdown .categoryList {
    width: 71%;
  }
  .careerBox .categoryList {
    width: 89%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 568px) {
  .one-col-layout .r1.c1,
  .one-col-layout .r2.c1,
  .one-col-layout .r3.c2,
  .one-col-layout .r1.c2 {
    width: 100%;
  }
  .banner-content h2 {
    font-size: 18px;
    margin-top: 30px;
  }
  .banner-content {
    margin: 15px 10px 0;
    width: 95%;
  }
  .hero-ban-home {
    background-size: auto;
    height: 300px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 600px) {
  #search-wrapper .col-1 {
    width: 68%;
  }
}

@media only screen and (max-width: 500px) {
  .container .col-l,
  .container .col-r,
  .container .col-l .r1.c1,
  .container .col-l .r2.c1 {
    width: 100%;
  }
  .collapse-box {
    margin: 0;
  }
  .collapse-box .collapsible-content h1 {
    font-size: 30px;
    padding-bottom: 10px;
  }
  #search-wrapper .col-2,
  #search-wrapper .col-3 {
    display: none;
  }
  #search-wrapper .col-1 {
    width: 100%;
  }
  .collapse-expand-a {
    margin-top: 20px;
  }
  .collapse-expand-a .block {
    padding-bottom: 20px;
  }
  .bottom-banner {
    padding-bottom: 100px;
  }
  .inpage-language-selector-container {
    padding-bottom: 30px;
  }
  .inpage-language-selector-container .language-selector {
    top: 10px;
  }
  .person-heading {
    margin-left: 0;
    width: 100%;
  }
  .bioSideBar .no-border .social-media-link-ls {
    top: 0;
  }
  #footer .row-1 .footer-link {
    float: left;
    width: 100%;
    text-align: center;
  }
  #footer .row-1 .footer-link ul li {
    display: inline-block;
    float: none;
    margin: 0 12px;
  }
  #footer .row-1 .social-media-link ul li {
    display: inline-block;
    margin: 0px 8px;
    float: none;
  }
  #footer .row-1 .social-media-link {
    float: left;
    margin-top: 10px;
    text-align: center;
    width: 100%;
    &:hover {
      img {
        display: none;
      }

      img:not(.aa) {
        display: block;
        opacity: 1;
      }
    }
  }
  #footer .row-1 {
    padding: 11px 0 15px;
  }
  #footer .row-2 .copy-right-link,
  #footer .row-2 .copy-right-txt {
    float: left;
    text-align: center;
    width: 100%;
  }
  #footer .row-2 .copy-right-txt {
    margin-top: 8px;
  }
  #footer .row-2 .copy-right-txt p {
    line-height: 15px;
  }
  #footer .row-2 .copy-right-link ul li {
    margin: 0;
    display: inherit;
  }
  #footer .row-2 .copy-right-link ul li:not(:first-child) {
    margin-left: 0;
  }

  .one-col-layout .r1.c1,
  .one-col-layout .r2.c1,
  .one-col-layout .r3.c2,
  .one-col-layout .r1.c2 {
    width: 100%;
  }
  .container .col-l .r3.c2,
  .container .col-l .r1.c1,
  .container .col-l .r2.c1,
  .container .col-r .r1.c1,
  .container .col-r .r2.c1 {
    height: auto;
  }
  .video-block {
    height: auto;
  }
  .video-block > img {
    width: 100%;
  }
  .container .col-l.last,
  .container .col-r.last {
    margin-bottom: 20px;
  }

  .bread-crumb {
    padding: 13px 0 20px;
  }
  .mobile-breadcrumb {
    padding: 16px 0 17px !important;
  }
  .bread-crumb ul {
    margin-left: 10px;
  }
  .white-container {
    padding-top: 17px;
  }
  .collapse-box .people-intro h1 {
    font-size: 32px;
    line-height: 1;
  }
  .detail-page .col-l h1 {
    font-size: 36px;
  }

  .bottom-banner {
    background-size: 150% !important;
  }
  .banner-content {
    margin: 15px 10px 0;
    width: 95%;
  }
  .banner-content h1 {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 6px;
  }
  .banner-content h2 {
    font-size: 18px;
    margin-top: 30px;
  }
  .main-heading h1 {
    font-size: 32px;
    padding-bottom: 0px;
    min-height: 131px;
  }
  .hero-ban.ban-3 {
    background-color: grey;
    height: 180px;
  }
  .subsection-list {
    padding-bottom: 16px;
    margin-bottom: 34px;
  }
  .collapse-box .people-intro .heading-brief {
    font-size: 16px;
    line-height: 20px;
  }
  .white-container {
    padding-bottom: 0px;
  }
  .collapse-box .people-intro h2.small-head {
    font-size: 28px;
  }
  .overlap-ban {
    width: auto;
  }

  .aa .block p {
    font-size: 14px;
  }
  .contact-list {
    background-color: #eeeeee;
    border-bottom: 0 solid #000000;
    margin-bottom: 36px;
    padding: 20px 20px 15px 20px;
  }
  .contact-list .thumbnail {
    display: none;
  }
  .contact-list .thumbnail-detail {
    padding-top: 0;
    width: auto;
  }
  .contact-list .show-more-link {
    padding: 8px 0 0;
  }
  
  h1.main-head {
    font-size: 32px;
  }
  .search-area {
    margin: 16px 0 14px;
  }
  .filter-search-head .right {
    width: 155px;
  }
  .search-result-area .block p {
    font-size: 14px;
  }
  .layout-mobile .related-source h1 {
	@include font-heading();
    background: url(../images/accordian-arrow-a.png) no-repeat 0px 0px;
    cursor: pointer;
    font-size: 24px;
    line-height: 31px;
    padding-left: 45px;
    text-transform: uppercase;
  }
  .layout-mobile .related-source h1.expand {
    background-position: 0 -110px;
  }
  .layout-mobile .related-source .refine-result-info {
    border-bottom: 2px solid #000000;
    display: none;
    padding-left: 45px;
    margin-bottom: 20px;
  }

  .alphaMainBox {
    padding: 0 20px;
  }
  .peopSearSect {
    margin-bottom: 15px;
  }
  .breadcrumb-overlay {
    width: auto;
  }

  .page-heading > h1 {
    font-size: 28px;
    line-height: 28px;
  }
  .aa .collapse-expand-a {
    margin-top: 0;
  }
  .person-depart-info-wrapper {
    padding: 0;
    margin-bottom: 20px;
  }
  .address-with-map .left {
    width: 100%;
  }
  .address-with-map .right {
    display: none;
  }
  .video-block-device {
    display: block;
  }
  .lightBoxWrapper,
  .videoLightBox {
    display: none;
  }
  .subsection-list > ul > li {
    background: none;
    padding-left: 0;
  }
  p.v-card a {
    @include font-semibold();
  }
  .layout-mobile .form-publication-section input#start_date-search[type="text"],
  .layout-mobile .form-publication-section input#end_date-search[type="text"] {
    width: 86%;
  }
  .white-container {
    margin-top: 0px;
  }
  .career-nest .white-container,
  .location-nest .white-container {
    margin-top: 0px;
  }
  .career-nest #careerOwlSlider {
    margin-top: 36px;
  }
  .hero-ban.ban-career,
  .hero-ban {
    height: 187px;
  }
  .language-sel {
    display: none;
  }

  /* .detail-page .col-l .collapse-box{ padding-top: 45px; } */
  .layout-mobile .detail-page .col-l .collapse-box {
    padding-top: 0px;
  }

  #search-wrapper .col-1 input[type="button"]#btnSiteSearchdevice {
    display: block;
  }
  #search-wrapper .col-1 input[type="text"] {
    padding-right: 62px;
  }
  .pubHiddenArea .publicationCheckOpt {
    width: 100%;
  }
  .global-search-sorting {
    width: 100%;
  }
  .people-search-result-inner-wraper
    ul
    li
    div.details
    p.position-loc
    span.mobileBioResult-loc,
  .people-search-result-inner-wraper ul li div.details span.mobileBioResult-ph {
    display: inline-block;
  }
  .col-r .right-box .location-det {
    display: none;
  }
  .col-l .location-det-ls.location-det {
    display: block;
    border: none;
  }

  .top-level-cont {
    margin: 0 3% 0px;
    padding-bottom: 0;
  }
  .top-level-cont .top-level-two-col .col-l,
  .top-level-cont .top-level-two-col .col-r,
  .top-level-cont .top-level-three-col .cols {
    width: 100%;
  }
  .top-level-cont .top-level-two-col .col-r {
    display: none;
  }
  .top-level-cont .top-level-two-col .col-l p {
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 0px;
  }
  .landing-page .layout-mobile #owl-example-outer-contaier {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .top-level-cont {
    border: none;
  }
  .top-level-cont .top-level-three-col .cols .block h2 {
    cursor: pointer;
    padding-left: 45px;
    text-transform: uppercase;
    padding-bottom: 16px;
  }
  .top-level-cont .top-level-three-col .cols .block h2.exp-coll-arrow {
    background: url("../images/accordian-arrow-a.png") no-repeat 0 0;
  }
  .top-level-cont .top-level-three-col .cols .block h2.exp-coll-arrow.expand {
    background-position: 0 -110px;
  }
  .layout-mobile .lp-inner-block {
    display: none;
    margin-left: 45px;
    padding-top: 7px;
  }
  .top-level-cont .top-level-three-col .cols.last .block {
    padding-bottom: 6px;
  }
  .top-level-cont .top-level-three-col .cols .block {
    padding-bottom: 27px;
  }
  .top-level-cont .top-level-three-col .cols .block h2:hover {
    background-position: 0 -219px;
    color: $color-purple;
  }
  .top-level-cont .top-level-three-col .cols .block h2.expand:hover {
    color: #000000;
  }

  .search-result-area ul li div.vcard {
    display: none;
  }
  .search-result-area ul li div.details {
    margin-left: 3%;
    width: 67%;
  }
  .search-result-area ul li div.m-image {
    width: 26%;
  }
  .filter-search-head .right-most {
    width: 118px;
  }
  .filter-search-head .right-most .sortSel {
    width: 116px;
  }
  .event-search-result-wrapper .filter-search-head .right-most .sortSel {
    width: 73px;
  } /* New Events Page */
  .filter-search-head .left {
    font-size: 16px;
    line-height: 33px;
  }
  .filter-search-head .mid a {
    margin-top: 4px;
  }
  .filter-search-head .right-most span.sort-by {
    margin: 6px 0px 0 0;
  }
  .pub-search-area > .filter-search-head .right-most {
    width: 118px;
  }
  .pub-search-area > .filter-search-head .right-most .sortSel {
    width: 116px;
  }
  .event-search-result-wrapper
    .pub-search-area
    > .filter-search-head
    .right-most
    .sortSel {
    width: 73px;
  } /* New Events Page */
  .peopSearSect .oneThird {
    width: 100%;
    margin-right: 0;
  }
  #divContactUs .oneThird input[type="text"] {
    width: 96%;
  }

  #divContactUs .captField span.captcha_field {
    border: none;
    margin-bottom: 9px;
    border-radius: 5px;
    padding-right: 10px;
    padding-top: 7px;
    width: 142px;
  }
  #divContactUs .captField input[type="text"] {
    width: 98%;
    border-left: 2px solid #000000;
  }
  .refreshBtn {
    display: none;
  }
  .resRefreshBtn {
    display: block;
  }
  #mapContainer .breadcrumb-overlay .bread-crumb ul {
    padding-left: 0px !important;
  }
  .career-nest .main-heading h1 {
    min-height: inherit;
    padding-bottom: 59px;
  }

  .person-detail-mob .perosn-detail {
    margin-top: 0px;
  }

  .people-intro.people-intro-ld {
    margin-top: 45px;
  }
}

@media only screen and (max-width: 480px) {
  .responsive-filter {
    display: block;
  }
  .filter-tiles-wrapper {
    display: none;
  }
  .lightbox,
  .lightboxBg {
    display: none !important;
  }
  .person-heading h1 {
    font-size: 28px;
    line-height: 28px;
  }
  .person-heading > .person-heading-container {
    padding-bottom: 15px;
  }

  .detail-page .col-l h1 {
    font-size: 36px;
  }
  .container .col-l.last,
  .container .col-r.last {
    margin-bottom: 20px;
  }
  #footer .row-2 .copy-right-txt p {
    margin: 0 32px;
  }
  #footer .row-2 .copy-right-txt p.res-sp {
    display: block;
  }
  #footer .row-2 .copy-right-txt p.res-dt {
    display: none;
  }
  .hero-ban-home {
    background: url("../images/home-page-banner.jpg") no-repeat scroll right
      bottom;
    height: 330px;
  }
  .hero-ban-home p {
    padding-top: 20px;
  }
  /*ourpeople*/
  .peopSearSect .oneHalf {
    width: 100%;
    float: none;
    margin-right: 0;
  }
  .resetLink {
    float: none;
    padding-bottom: 30px;
    padding-top: 5px;
  }
  .resetLink2 {
    display: block;
    padding: 10px 0 20px;
    text-align: center;
    float: none;
  }

  .moreOption {
    display: block;
  }
  .hiddenForm {
    display: none;
  }
  .hiddenForm h3 {
    display: none;
  }
  .enterBtn {
    float: none;
  }
  .enterBtn input {
    width: 48%;
  }
  .peopSearSect input.nameField {
    margin-bottom: 20px;
  }

  /*13-10*/
  .careerBox {
    padding: 17px 10px;
  }
  .careerBox h2 {
    font-size: 24px;
    padding-right: 0px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    line-height: 24px;
  }
  .careerBox label {
    line-height: 36px;
  }

  .careerBox .categoryList {
    width: 100%;
    float: left;
  }
  .careerBox .alumniDropdown .categoryList {
    width: 100%;
    float: none;
  }

  .careerBox input.jobEnterBtn {
    width: 100%;
  }
  #careerOwlSlider.owl-theme .owl-controls {
    bottom: inherit;
    left: 50%;
    margin-left: -40px;
    top: -40px;
  }

  /*14-10*/
  .publicationTypes {
    padding-bottom: 0px;
  }
  .publicationTypes h3 {
    float: left;
    width: 50%;
  }
  .publicSelectAll {
    width: 100%;
    margin-bottom: 18px;
  }
  .publicationCheckOpt {
    width: 100%;
    float: none;
    clear: both;
  }
  .nameLabel {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }
  .pubYearDropdown {
    width: 100%;
    float: none;
  }
  .form-publication-section {
    border-bottom: 7px solid #000000;
    margin-bottom: 18px;
    padding: 0px 0 18px;
  }

  .pubReset {
    float: none;
    padding: 15px 0px;
  }
  .pubSearchBtn {
    float: none;
  }
  .people-search-result-inner-wraper ul li div.contact,
  .people-search-result-inner-wraper ul li div.vcard {
    display: none;
  }
  .people-search-result-wrapper .filter-search-head .left,
  .media-result-wrapper .filter-search-head .left {
    display: block;
  }
  #searchHeader.filter-search-head .mid {
    margin-left: 0;
  }
  .people-search-result-inner-wraper ul li div.m-image {
    width: 24%;
  }
  .people-search-result-inner-wraper ul li div.details {
    width: 60%;
  }
  .people-search-result-wrapper .filter-search-head .right,
  .media-result-wrapper .filter-search-head .right {
    width: 151px;
  }
  .filter-search-head .mid {
    line-height: normal;
    margin-left: 0;
  }
  .filter-search-head .mid a {
    display: block;
  }
  .result-found-res {
    display: none;
    width: 100%;
  }
  .result-found-res p {
    float: none;
    font-size: 18px;
  }
  .filter-search-head {
    padding-bottom: 20px;
  }
  /* fon news and press release */
  .block-wrapper .block.half-col {
    width: 100%;
  }
  .media-result-inner-wraper ul li div.description {
    display: none;
  }
  .media-result-inner-wraper ul li div.date {
    width: 26%;
    font-size: 14px;
  }
  .media-result-inner-wraper ul li div.title {
    width: 67%;
  }
  .search-media-form,
  .media-resource-wrapper {
    margin: 0 3% 18px;
  }
  .media-result-wrapper {
    margin: 0 3% 25px;
  }
  .pagination {
    padding-bottom: 20px;
  }
  .enterBtn input {
    margin-left: 0;
    margin-right: 5px;
  }
  .media-result-wrapper .stylish-select-right {
    font-size: 15px;
  }
  .two-eqcol-layout .block {
    margin-right: 0;
    width: 100%;
  }
  #start_date-search,
  #end_date-search {
    width: 87%;
  }
  .fewerOption {
    padding: 6px 0 15px;
  }
  .media-result-wrapper .filter-search-head .right {
    float: right;
  }
  .pub-search-area .filter-search-head .left a {
    line-height: 77px;
  }
  .people-intro {
    padding-bottom: 0px;
  }
  .owl-carousel-people h2 {
    padding-right: 50px;
    line-height: 20px;
  }
  #careerOwlSlider {
    height: 224px;
    min-height: inherit;
  }
  .career-nest .load-more {
    display: none;
  }
  .video-block-device {
    display: block;
  }
  .categoryList .main ul.listing {
    max-height: 62px;
  }
  .moreOption {
    padding-bottom: 0;
  }
  .peopSearSect {
    padding-bottom: 24px;
  }
  .hero-ban.no-ban {
    min-height: 22%;
  }
  .alternate-details {
    display: none;
  }
  .show-more-link1 {
    padding-bottom: 17px;
  }
  .subsection-list {
    padding-bottom: 17px;
    margin-bottom: 35px;
  }
  .collapse-box .people-intro p {
    padding-bottom: 0px;
  }
  .collapse-box {
    margin-bottom: 0px;
    margin-top: 0px !important;
  }
  .captField span.captcha_field {
    width: 56%;
  }

  .capchaBox .refreshBtn {
    float: right;
  }
  .person-depart-info {
    padding: 0 0 25px;
  }
  #searchHeader.filter-search-head .right {
    float: none;
  }
  #searchHeader.filter-search-head .right .sortSel {
    width: 100%;
  }
  #divContactUs .oneHalf {
    float: left;
  }
  #divContactUs input[type="text"],
  #divContactUs select {
    width: 96%;
  }
  #divContactUs .oneHalf .main {
    width: 94.5%;
  }
  #divContactUs textarea {
    width: 96%;
  }
  #globalsearchresult1 > ul#divPeopleListing li div.m-image {
    width: 25%;
  }
  #globalsearchresult1 > ul#divPeopleListing li div.details {
    width: 70%;
  }
  #globalsearchresult1 > ul#divPeopleListing li div.vcard {
    display: none;
  }
  
  .breadcrumsection {
    min-height: 70px;
    @media only screen and (max-width: 640px) {
      min-height: 45px;
    }
  }
  

  .breadcrumsection .desktop-breadcrumb,
  .breadcrumsection .mobile-breadcrumb {
    width: 62%;
  }
  .careerBox .categoryList input {
    width: 95.4%;
  }
  .landing-page .layout-mobile #owl-example-outer-contaier {
    margin-bottom: 10px;
  }
  /*.form-publication-section input[type="text"]#start_date-search, .form-publication-section input[type="text"]#end_date-search{ width: 89%; }*/
  .search-media-form input[type="text"]#start_date-search,
  .search-media-form input[type="text"]#end_date-search {
    width: 89%;
  }
}

@media only screen and (max-width: 375px) {
  .careerBox .categoryList input {
    width: 94%;
  }
}

@media only screen and (max-width: 320px) {
  .owl-carousel-people h2 {
    font-size: 20px;
  }
  #divContactUs .captField input[type="text"] {
    width: 90%;
  }
  .careerBox .categoryList input {
    width: 93%;
  }
  .layout-mobile .form-publication-section input#start_date-search[type="text"],
  .layout-mobile .form-publication-section input#end_date-search[type="text"] {
    width: 84%;
  }
  .search-media-form input[type="text"]#start_date-search,
  .search-media-form input[type="text"]#end_date-search {
    width: 86%;
  }
}

/* autosuggest for global search */

@media only screen and (max-width: 956px) {
  ul.autocomplete-width {
    width: 98% !important;
  }
}

@media only screen and (max-width: 760px) {
  ul.autocomplete-width {
    width: 97% !important;
  }
}

@media only screen and (max-width: 640px) {
  ul.autocomplete-width {
    width: 97% !important;
  }
}

/*400-500*/
@media only screen and (max-width: 500px) {
  ul.autocomplete-width {
    width: 95% !important;
  }
}
@media only screen and (max-width: 475px) {
  ul.autocomplete-width {
    width: 94.75% !important;
  }
}
@media only screen and (max-width: 450px) {
  ul.autocomplete-width {
    width: 94.5% !important;
  }
}
@media only screen and (max-width: 425px) {
  ul.autocomplete-width {
    width: 94.25% !important;
  }
}
@media only screen and (max-width: 400px) {
  ul.autocomplete-width {
    width: 94% !important;
  }
}

/*300-375*/
@media only screen and (max-width: 375px) {
  ul.autocomplete-width {
    width: 93.75% !important;
  }
}
@media only screen and (max-width: 350px) {
  ul.autocomplete-width {
    width: 93.5% !important;
  }
}
@media only screen and (max-width: 325px) {
  ul.autocomplete-width {
    width: 92.8% !important;
  }
}
@media only screen and (max-width: 300px) {
  ul.autocomplete-width {
    width: 92.5% !important;
  }
}

.tile-date{
  @include font-content();
  @include font(14px, 15px);
  color: $color-medium-grey;
}

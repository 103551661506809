﻿.share-tools {
    position: absolute;
    top: 60px;
    z-index: 5999;

    .st-custom-button {
        background: none !important;
        border: none !important;
        height: 40px !important;
        width: 40px !important;
    }

    .st-custom-button[data-network=linkedin],
    .st-custom-button[data-network=facebook],
    .st-custom-button[data-network=twitter] {
        margin-bottom: 0 !important;
    }

    &--sticky {
        position: fixed;
        top: 80px !important;
    }

    &__container {
        margin-left: -12vw;
        position: relative;
        width: 48px;
        border-radius: 48px;
        background-color: rgb(255, 255, 255, 0.45);
        max-height: 175px;
        overflow: hidden;
        top: 183px;
        text-align: center;
        padding: 5px 0;

        &:hover {
            background-color: rgb(255, 255, 255, 0.65);
        }

        @include for-print() {
            display: none;
        }

        &--narrow {
            display: none;
        }

        &--wide {
            &-open {
                height: 310px;
                top: 48px;
                max-height: inherit
            }
        }

        &__icon {
            &-close {
                display: none;
            }

            &--open {
                display: inline-block;
            }

            &--closed {
                display: none;
            }
        }

        &__toggle-container {
            margin-left: 0;
            position: relative;
            cursor: pointer;
            right: 0;
            top: calc(100% - 311px);
            transition: transform .3s ease-in, opacity .3s ease-in;
            opacity: 0;
            visibility: hidden;

            &--open {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }

            &--closed {
                visibility: hidden;
                opacity: 0;
                transform: translateY(2%);
                height: 0;
            }
        }

        &__custom-icon {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 0;
            padding-left: 0;
            padding-right: 0;
            filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.16));

            &:focus-visible {
                @include focus-outline();
            }

            svg {
                height: 40px;
                width: 40px;

                .st0 {
                    fill: $color-white;
                }

                .st1, .st2 {
                    fill: $color-light-grey;
                }

                .st3 {
                    fill: $color-white;
                }

                &:hover {
                    .st0, .st1 {
                        fill: $color-black;
                    }

                    .st2 {
                        fill: $color-white;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1017px) {
        &__container {
            width: 48px;

            &--wide {
                display: none;
            }

            &--narrow {
                display: block;

                @include for-print() {
                    display: none;
                }
            }

            &__icon {
                cursor: pointer;
                display: block;
                position: fixed;
                right: 0;
                top: calc(100% - 125px);
                margin-right: 4px;

                &--closed {
                    display: none;
                }
            }

            &__custom-icon {
                padding: 2.3px 0;

                svg {
                    height: 46px;
                    width: 46px;
                }
            }

            .st-custom-button[data-network=linkedin],
            .st-custom-button[data-network=facebook],
            .st-custom-button[data-network=twitter] {
                margin-bottom: 6px !important;
            }

            &__toggle-container {
                cursor: pointer;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                flex-direction: column;
                position: fixed;
                right: 0;
                margin-right: 4px;
                top: calc(100% - 377px);
                padding: 2px 0;
                width: 48px;
                border-radius: 48px;
                transition: transform .3s ease-in, opacity .3s ease-in;
                opacity: 0;
                visibility: hidden;

                &--open {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0);
                    background-color: rgb(255, 255, 255, 0.65);
                }

                &--closed {
                    visibility: hidden;
                    opacity: 0;
                    transform: translateY(1%);
                }
            }
        }
    }
}

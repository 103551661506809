/* General CSS */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  font-size: 16px;
  color: #414042;
  @include font-content();
}

input[type="text"],
textarea,
input[type="submit"],
input[type="button"],
input[type="reset"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}

p {
  @include font-content();
  color: #414042;
  font-size: 16px;
  margin-bottom: 16px;
}

ul {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #010101;
  font-family: arial;
  font-weight: normal;
}

h5 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
}

h6 {
  @include font-heading();
  color: $color-purple;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
}

a {
  color: #000000;
  text-decoration: none;
}

a:hover {
  color: $color-purple;
}

a:focus {
  color: $color-purple;
}

input:focus,
textarea:focus {
  outline: none;
}

*::-moz-selection {
  background: none repeat scroll 0 0 $color-purple;
  color: #fff;
}

*::selection {
  background: none repeat scroll 0 0 $color-purple;
  color: #fff;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

a:focus {
  box-shadow: none;
  outline: dotted 1px #000;
}

.clr:before,
.clr:after {
  content: "";
  display: table;
}

.clr:after {
  clear: both;
}

.clr {
  zoom: 1;
  clear: both;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.no-padding {
  padding: 0;
}

/* Reset CSS */
.header-outer {
  min-height: 152px;
}

header {
  border-bottom: 2px solid #000000;
  background-color: #ffffff;
  position: relative;
  z-index: 999;
  width: 100%;
}

header .headerCont {
  padding: 10px 0 0px;
}

.container,
#footer .container,
#language-select-container .container {
  margin: 0px auto;
  width: 940px;
  position: relative;
}

#footer .container,
#language-select-container .container {
  line-height: initial;
  padding-left: 0px;
  padding-right: 0px;

  @media only screen and (max-width: 956px) {
    width: 100%;
  }

  ul,
  ol {
    margin-bottom: 0;
    margin-top: 0;

    a {
      line-height: initial;
    }
  }
}

.global-search {
  float: left;
}

.global-search input.btn-global-search {
  background: url(../images/icon-search.gif) no-repeat 0 0;
  border: medium none;
  cursor: pointer;
  height: 38px;
  padding: 0;
  text-indent: -999999px;
  width: 38px;
  overflow: hidden;
}

.logo-wrapper {
  text-align: center;
  margin-top: 5px;
}

.logo-wrapper a {
  display: inline-block;
}

.sticky-header .logo-wrapper a {
  width: 100%;
}

/* navigation start */
#nav-wrapper {
  margin: 15px auto 0px auto;
  text-align: center;
  position: relative;
}
/* #nav-wrapper ul.nav-main-link{ margin-left: 155px; } */
#nav-wrapper ul.nav-main-link > li {
  /*float: left;*/
  margin: 0 10px; /*padding: 0 9px ;*/
  display: inline-block;
}

#nav-wrapper ul.nav-main-link > li > a {
  @include font-content();
  color: $color-black;
  display: block;
  font-size: 18px;
  line-height: 23px;
  position: relative;
  padding: 0 9px 16px 9px;
}

#nav-wrapper ul.nav-main-link > li > a:hover {
  color: $color-purple;
}

#nav-wrapper ul.nav-main-link > li > a:focus {
  color: $color-purple;
  outline: 1px dotted #000;
}

#nav-wrapper ul.nav-main-link > li > a.selected {
  @include font-semibold();
  color: $color-purple;
}

#nav-wrapper ul.nav-main-link > li > a span.menu-arrow {
  bottom: -11px;
  display: none;
  height: 12px;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 26px;
  z-index: 2;
  background: none;
}

#nav-wrapper ul.nav-main-link > li:hover > a span.menu-arrow {
  display: block;
  background: url(../images/nav-arrow.png) no-repeat 0 0;
  color: $color-purple;
}

#nav-wrapper ul.nav-main-link > li > a.open span.menu-arrow {
  display: block;
  background: url(../images/nav-arrow.png) no-repeat 0 0;
  color: $color-purple;
}

#nav-wrapper ul.nav-main-link > li:focus > a span.menu-arrow {
  display: block;
  background: url(../images/nav-arrow.png) no-repeat 0 0;
  color: $color-purple;
  outline: 1px dotted #000;
}

.ie7 #nav-wrapper ul.nav-main-link > li > a span.menu-arrow {
  bottom: -15px;
  color: $color-purple;
}

#nav-wrapper ul.nav-main-link > li > .sub-menu {
  background-color: #643480;
  color: #fff;
  display: none;
  font-size: 15px;
  left: 0;
  padding: 0px 0px 41px 0px;
  position: absolute;
  text-transform: capitalize;
  top: 40px;
  width: 100%;
  z-index: 1;
}

#nav-wrapper ul.nav-main-link > li > .sub-menu.open {
  display: block;
}

.close-wrapper {
  padding: 10px 0 7px 0;
}

.icon-menu-close {
  background: url(../images/icon-menu-close.png) no-repeat;
  cursor: pointer;
  float: right;
  height: 24px;
  margin-right: 10px;
  width: 24px;
}

#nav-wrapper ul.nav-main-link > li > .sub-menu .col-1 {
  float: left;
  width: 32%;
  margin-right: 2%;
  position: relative;
}

#nav-wrapper ul.nav-main-link > li > .sub-menu .col-2 {
  float: left;
  width: 66%;
}

#nav-wrapper ul.nav-main-link > li > .sub-menu a {
  @include font-heading();
  color: $color-white;
}

.padding-10 {
  padding: 0px;
}

.padding10 {
  padding: 10px;
}

.padding-lr {
  padding: 0 10px;
}

#nav-wrapper ul.nav-main-link > li > .sub-menu .col-1 ul.level-1 {
  margin-left: 10px;
}

#nav-wrapper ul.nav-main-link > li > .sub-menu .col-1 ul.level-1 > li {
  margin-bottom: 42px;
  text-align: left;
}

#nav-wrapper
  ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  ul.level-1
  > li:last-child {
  margin-bottom: 0px;
}

#nav-wrapper ul.nav-main-link > li > .sub-menu .col-1 ul.level-1 > li a {
  @include font-heading();
  color: $color-white;
  font-size: 28px;
  display: block;
  padding: 2px 10px;
  text-transform: uppercase;
}

#nav-wrapper ul.nav-main-link > li > .sub-menu .col-1 ul.level-1 > li a:hover,
#nav-wrapper ul.nav-main-link > li > .sub-menu .col-1 ul.level-1 > li a:focus,
#nav-wrapper
  ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  ul.level-1
  > li
  > a.hover {
  background-color: #ffffff;
  color: $color-purple;
}

#nav-wrapper
  ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  ul.level-1
  > li
  ul.level-2 {
  margin-top: 9px;
}

#nav-wrapper
  ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  ul.level-1
  > li
  ul.level-2
  > li {
  margin-bottom: 6px;
}

ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  ul.level-1
  > li
  ul.level-2
  > li:last-child {
  margin-bottom: 0px !important;
}

#nav-wrapper
  ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  ul.level-1
  > li
  ul.level-2
  > li
  > a {
  @include font-heading();
  font-size: 16px;
  line-height: 22px;
  display: block;
  padding: 7px 10px;
}

#nav-wrapper
  ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  ul.level-1
  > li
  ul.level-2
  > li
  > a:hover,
#nav-wrapper
  ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  ul.level-1
  > li
  ul.level-2
  > li
  > a:focus,
#nav-wrapper
  ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  ul.level-1
  > li
  ul.level-2
  > li
  > a.hover {
  background-color: #ffffff;
  color: #000;
}

#nav-wrapper ul.nav-main-link li.selectedMenu > a {
  @include font-semibold();
  color: #000000;
}

.sec-level-sub-menu {
  left: 284px;
  padding-left: 32px;
  padding-bottom: 40px;
  position: absolute;
  top: 0;
  width: 605px;
  display: none;
}

.sec-level-sub-menu.hover1 {
  display: block;
}

.sec-level-sub-menu .col-block {
  float: left; /*margin-right: 3.5%;*/
  width: 48.25%;
}

.sec-level-sub-menu .col-block.last {
  margin-right: 0;
}

.sec-level-sub-menu .col-block ul li {
  line-height: 20px;
  margin-bottom: 0px;
}

#nav-wrapper
  ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  .sec-level-sub-menu
  > .col-block
  > div
  > div.hoverDiv
  > ul
  > li
  > a {
}

.sec-level-sub-menu .col-block ul li a {
  display: block !important;
  font-size: 16px !important;
  line-height: 20px !important;
  padding: 10px !important;
  text-transform: none !important;
}

.sec-level-sub-menu .col-block ul li a:hover,
.sec-level-sub-menu .col-block ul li a:focus,
.sec-level-sub-menu .col-block ul li a.hover {
  background-color: #ffffff;
  color: #000 !important;
}

/* navigation end */

/* our people sub-menu start */

.view-all-link {
  border-bottom: 1px dotted #ffffff;
  padding-bottom: 4px;
  text-align: left;
}

.search-mblock-bg {
  /*background-color: #6f3a8e;*/
  padding: 20px 20px 0px 20px;
  text-align: left;
}

.search-mblock-bg p {
  @include font-semibold();
  color: #ffffff;
}

.frmSearch .block-1,
.frmSearch .block-2 {
  float: left;
  margin-bottom: 20px;
  margin-right: 2%;
}

.frmSearch .block-1 input[type="text"],
.frmSearch .block-2 input[type="text"] {
  @include font-medium();
  padding: 8px 5px;
  width: 99%;
  -webkit-appearance: none;
}

.frmSearch .block-1 input[type="text"] {
  width: 99%;
}

.frmSearch .block-2 input[type="text"] {
  width: 97%;
}

.frmSearch .block-2 .main {
  border: none;
}

.frmSearch .block-2 select {
  @include font-heading();
  color: $color-grey;
  width: 99%;
  padding: 8px 5px;
  text-transform: uppercase;
}

.frmSearch .block-1 {
  width: 100%;
}

.frmSearch .block-2 {
  width: 32%;
}

.frmSearch .block-1.last,
.frmSearch .block-2.last {
  margin-right: 0px;
}

.frmSearch .block-1.last-m {
  margin-bottom: 9px;
}

.frmSearch .block-1 input[type="submit"] {
  @include font-heading();
  background-color: #643480;
  border: 2px solid #ffffff;
  color: $color-white;
  cursor: pointer;
  float: right;
  font-size: 15px;
  padding: 10px 15px;
  text-transform: uppercase;
  -webkit-appearance: none;
}

.frmSearch .block-2 ul.listing {
  border: none;
  top: 41px;
  left: 0px;
}

.stylish-select-left > .stylish-select-right,
ul.listing li {
  // font-weight: normal !important;
}
/* our people sub-menu end */
#responsiv-nav-btn {
  display: none;
}

.sticky-header {
  position: fixed;
  min-height: 60px;
  width: 100%;
  z-index: 998;
}

.sticky-header #responsiv-nav-btn {
  cursor: pointer;
  display: block;
  float: left;
  height: 38px;
  margin-right: 20px;
  width: 38px;
}

.sticky-header #responsiv-nav-btn.res-nav-icon {
  background: url(../images/responsiv-nav-btn.png) no-repeat 0px 0px;
}

.sticky-header #responsiv-nav-btn.res-nav-icon-close {
  background: url(../images/responsiv-nav-btn.png) no-repeat 0px -51px;
}

.sticky-header #nav-wrapper {
  display: none;
}

.sticky-header .logo-wrapper {
  left: 50%;
  margin: 7px -90px 0 auto;
  margin-left: -90px;
  position: absolute;
  top: 10px;
  width: 181px;
}

.sticky-header .logo-wrapper img {
  max-width: 100%;
}

.sticky-header .headerCont {
  padding: 10px 0 10px;
}

#search-wrapper {
  background-color: $color-purple;
  padding: 20px 0;
  display: none;
}

#search-wrapper .col-1,
#search-wrapper .col-2 {
  float: left;
}

#search-wrapper .col-3 {
  float: right;
}

#search-wrapper .col-1 {
  width: 81%;
  margin-right: 0.3%;
  position: relative;
}

#search-wrapper .col-2 {
  width: 14%;
}

#search-wrapper .col-3 {
  width: 4%;
}

#search-wrapper .col-1 input[type="text"] {
  @include font-medium();
  width: 99%;
  *width: 95%;
  border: none;
  color: #7f7f7f;
  font-size: 16px;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

#search-wrapper .col-1 input[type="text"]:focus {
  color: #000;
}

#search-wrapper .col-1 input[type="button"]#btnSiteSearchdevice {
  background: url(../images/btn-search-mobile.png) no-repeat 0px 0;
  border: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  position: absolute;
  right: 8px;
  top: 7px;
  text-indent: -9999px;
  display: none;
}

#search-wrapper .col-2 input[type="submit"],
#search-wrapper .col-2 input[type="button"] {
  @include font-heading();
  border: 2px solid #ffffff;
  background-color: $color-purple;
  color: $color-white;
  font-size: 16px;
  padding: 12px 38px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  cursor: pointer;
}

#search-wrapper .col-3 a.icon-close {
  background: url("../images/icon-menu-close.png") no-repeat 0 0;
  cursor: pointer;
  float: right;
  height: 24px;
  margin-top: 14px;
  width: 24px;
}

#search-wrapper .col-3 a.icon-close:focus {
  outline: 1px dotted #000;
}

/* content wrapper start */
.content-wrapper {
  min-height: 540px;
}

/* Person Detail Page footer banner - start */
.bottom-banner {
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 260px;
}
/* Person Detail Page footer banner - end */

/* Hero Banners - start */
.hero-ban {
  background-repeat: repeat-x;
  background-position: 0px 0px;
  background-size: cover;
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 316px;
}

.hero-ban.ban-people-main {
  height: 610px;
}

.hero-ban.ban-career {
  height: 610px;
}

.hero-ban.no-ban {
  background-image: url(../images/ban-no.jpg);
  background-repeat: repeat-x;
  background-size: auto;
  height: 70px;
}

.hero-ban-home {
  background: url(../images/home-page-banner.jpg) no-repeat right bottom;
  border-bottom: 7px solid #000000;
  height: 245px;
}

.hero-ban-home p {
  @include font-medium();
  color: $color-purple;
  list-style: 24px;
  padding-top: 60px;
  width: 300px;
}
/* Hero Banners - end */

.overlap-ban {
  width: 100%;
  margin: 0 auto;
}

.white-container {
  background-color: #fff;
  padding-top: 55px;
  padding-bottom: 20px;
}

.container .col-l,
.container .col-r {
  float: left;
  margin-right: 2%;
}

.container .col-l.last,
.container .col-r.last {
  margin-right: 0px;
}

.container .col-l {
  width: 66%;
}

.container .col-r {
  width: calc(32% - 8px);
}

.collapse-box {
  margin: 0 12.90322580645161%;
  border-bottom: 8px solid #231f20;
  position: relative;
}

.collapse-box.no-border {
  padding-bottom: 0px;
  border-bottom: 0px solid #231f20;
}

.collapse-box .people-intro h1 {
  @include font-heading();
  color: $color-black;
  font-size: 48px;
  line-height: 48px;
  padding-bottom: 23px;
  text-transform: uppercase;
}

.collapse-box .people-intro h3 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  text-transform: uppercase;
}

.collapse-box .people-intro h2.small-head {
  font-size: 36px;
  text-transform: uppercase;
  padding-bottom: 8px;
}

.collapse-box .people-intro span.small-head-next {
  @include font-content();
  color: #7b7b7e;
  font-size: 14px;
  display: block;
}

.pub-heading {
  padding-bottom: 30px;
}

.people-intro {
  position: relative;
  padding-bottom: 28px;
}

.people-intro img {
  max-width: 100%;
}

.people-intro > ul {
  padding-bottom: 10px;
}

.people-intro > ul > li {
  @include font-content();
  background: url("../images/arrow-light-grey1.png") no-repeat scroll 0 5px;
  color: #414042;
  display: block;
  font-size: 16px;
  padding-left: 18px;
}

.people-intro > ul > li a {
  @include font-content();
  color: #414042;
}

.people-intro > ul > li a:hover,
.people-intro ul > li a:focus {
  color: #000000;
}

.people-intro > ul > li ul li {
  @include font-content();
  background: url("../images/arrow-light-grey1.png") no-repeat scroll 0 5px;
  color: #414042;
  display: block;
  font-size: 16px;
  padding-left: 18px;
}

.visible-cont ul,
.hidden-cont ul,
.search-result-area .block ul {
  margin-bottom: 10px;
}

.visible-cont ul li,
.hidden-cont ul li,
.search-result-area .block ul li {
  @include font-content();
  font-size: 16px;

  @include for-print() {
    @include font-content();
  }
}

.collapse-box > ul {
  margin-bottom: 10px;
}

.collapse-box > ul li {
  @include font-content();
  background: url("../images/arrow-light-grey1.png") no-repeat scroll 0 5px;
  color: #414042;
  display: block;
  font-size: 16px;
  padding-left: 18px;
}

.purple-para .heading-brief {
  line-height: 24px;
  @include richtext-ul($padding-left: 17px);
}

.purple-para p {
  line-height: 20px;
}

.purple-para ul {
  margin: 6px 0 10px 0;
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 17px;
}

.purple-para ul ul {
  list-style-type: circle;
}

.purple-para ul ul ul {
  list-style-type: square;
}

.purple-para ol {
  margin: 6px 0 10px 0;
}

.purple-para ol > li {
  margin-left: 18px;
}

.purple-para li {
  @include font-content();
  color: #414042;
  font-size: 16px;
  margin-bottom: 8px;
}

.purple-para ul > li::marker {
  color: #6244b3;
  font-size: calc(105%);
}

.purple-para.arrow-class ul > li {
  background: none;

  &.a {
    list-style-type: disc;
    display: list-item;
  }
}

.purple-para li:last-child {
  margin-bottom: 0;
}

.visible-cont p a,
.purple-para p a,
.collapse-box p a,
.heading-brief a,
.collapse-expand-cont p a,
.hidden-cont p a,
.search-result-area .block p a,
.purple-para ol li a {
  color: #000000;
  text-decoration: underline;
}

.visible-cont p a:hover,
.purple-para p a:hover,
.collapse-box p a:hover,
.heading-brief a:hover,
.collapse-expand-cont p a:hover,
.hidden-cont p a:hover,
.search-result-area .block p a:hover,
.purple-para ol li a:hover {
  color: $color-purple;
  text-decoration: none;
}

input#CaptchaText {
  text-transform: none;
  padding: 10px 0px 11px 0px \0 / IE9;
  margin: 0;
}

.btnRight {
  float: right !important;
}

.people-intro .person-heading {
  margin-left: 0;
  padding-bottom: 30px;
  position: absolute;
  top: -129px;
  left: 0;
  width: 730px;
}

.people-intro .person-heading span {
  @include font-heading();
  color: $color-purple;
  display: block;
  padding-bottom: 8px;
  text-transform: uppercase;
}

.people-intro .person-heading h1 {
  @include font-heading();
  font-size: 36px;
  text-transform: uppercase;
  padding-bottom: 0px;
}

.people-intro .person-heading h2 {
  font-size: 24px;
  text-transform: uppercase;
}

.collapse-box .people-intro p,
.collapse-box .oniblock p {
  @include font-content();
  line-height: 20px;
}

.collapse-box .people-intro .address {
  @include font-content();
  line-height: 20px;
}

.collapse-box .people-intro .heading-brief,
.collapse-box .people-intro .purple-para .heading-brief,
.collapse-box .people-intro .heading-brief p,
.collapse-box .oniblock .heading-brief,
.collapse-box .oniblock .heading-brief p {
  @include font-medium();
  color: $color-purple;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 0px;
}

.collapse-box .purple-para h3 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  text-transform: uppercase;
}

.heading-brief {
  @include font-medium();
  color: $color-purple;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 0px;
}

.collapse-box .people-intro .heading-brief,
.collapse-box .oniblock .heading-brief,
.collapse-box .oniblock .heading-brief {
  color: $color-purple;
}

.show-more-link {
  padding: 4px 0px 33px 0px;

  @include for-print() {
    display: none;
  }
}

.show-more-link1 {
  padding: 4px 0px 36px 0px;
}

.show-more-link a,
.show-more-link1 a {
  @include showmore-trigger--small();

  @include for-print() {
    display: none;
  }
}

.show-more-link a:before,
.show-more-link1 a:before {
  @include font(10px, 16px);
  @include icon();
  @include icon-plus();
  line-height: 16px;
  margin-right: 8px;
  vertical-align: text-top;
}

.show-more-link a:hover,
.show-more-link a:focus,
.show-more-link1 a:hover,
.show-more-link1 a:focus {
  background-position: 0px -97px;
  color: $color-purple;
}

.show-more-link a.expand:before,
.show-more-link1 a.expand:before {
  @include font(10px, 16px);
  @include icon();
  @include icon-minus();
  line-height: 16px;
  margin-right: 8px;
  vertical-align: text-top;
}

.show-more-link a.expand:hover,
.show-more-link a.expand:focus,
.show-more-link1 a.expand:hover,
.show-more-link1 a.expand:focus {
  background-position: 0px -147px;
}

.collapse-expand-a .block {
  padding-bottom: 39px;

  button {
    text-align: left;
    color: $color-black;

    &:not(.expand) {
      &:hover,
      &:focus {
        color: $color-purple;
        background: url(../images/icon-accordian-arrow-hover.png) no-repeat 0px
          0px;
        background-size: 28px 28px;

        @include for-print() {
          background: none;
        }
      }
    }
  }
}

.collapse-expand-a .block h2 {
  @include font-heading();
  background: url(../images/icon-accordian-arrow.png) no-repeat 0px 0px;
  background-size: 28px 28px;
  cursor: pointer;
  font-size: 28px;
  line-height: 29px;
  padding-left: 45px;
  text-transform: uppercase;

  @include respond-to-max("small") {
    font-size: 24px;
  }

  &:focus-visible {
    @include focus-outline();
  }
}

.collapse-expand-a .block h2.expand {
  background: url(../images/icon-accordian-arrow-active.png) no-repeat 0px 0px;
  background-size: 28px 28px;

  &:focus-visible {
    @include focus-outline();
  }
}

.collapse-expand-a .block button {
  @include font-heading();
  background: url(../images/icon-accordian-arrow.png) no-repeat 0px 0px;
  background-size: 28px 28px;
  border: none;
  color: $color-black;
  cursor: pointer;
  font-size: 28px;
  line-height: 29px;
  padding-left: 45px;
  text-align: left;
  text-transform: uppercase;

  @include respond-to-max("small") {
    font-size: 24px;
  }

  &:focus-visible {
    @include focus-outline();
  }
}

.collapse-expand-a .block button.expand {
  background: url(../images/icon-accordian-arrow-active.png) no-repeat 0px 0px;
  background-size: 28px 28px;

  &:focus-visible {
    @include focus-outline();
  }
}

.collapse-expand-a .block.noicon button {
  background: none;
  cursor: default;
  padding-left: 0;
}

.collapse-expand-a .block.noicon h2 {
  background: none;
  padding-left: 0;
}

.collapse-expand-a .block h2.expand:hover,
.collapse-expand-a .block h2.expand:focus {
  background: url(../images/icon-accordian-arrow-active.png) no-repeat 0px 0px;
  background-size: 28px 28px;
  color: #000000;
}

.collapse-expand-a .block h2:hover,
.collapse-expand-a .block h2:focus {
  background: url(../images/icon-accordian-arrow-hover.png) no-repeat 0px 0px;
  background-size: 28px 28px;
  color: $color-purple;
}

.collapse-expand-a .block.noicon h2.expand:hover,
.collapse-expand-a .block.noicon h2.expand:focus,
.collapse-expand-a .block.noicon h2:hover,
.collapse-expand-a .block.noicon h2:focus {
  background: none;
  color: #000000;
  cursor: default;
  padding-left: 0;
}

.collapse-expand-a .block .collapse-expand-cont {
  margin-top: 23px;
  display: none;
  padding-left: 46px;
}

.collapse-expand-a .block.noicon .collapse-expand-cont {
  padding-left: 0;
}

.collapse-expand-a .block.open .collapse-expand-cont {
  display: block;
}

.collapse-expand-a .block .collapse-expand-cont p {
  @include font-content();
  line-height: 1.25em;
}

.collapse-expand-a .block .collapse-expand-cont p a:hover,
.collapse-expand-a .block .collapse-expand-cont p a:focus {
  color: $color-purple !important;
  text-decoration: none;
}

.collapse-expand-a .block .collapse-expand-cont ul {
  &:not(.chevron) {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 17px;

    li {
      color: $color-dark-grey;
      position: relative;

      &::marker {
        color: $color-purple-bullet;
        font-size: 105%;
      }
    }
  }
}

.collapse-expand-a .block .collapse-expand-cont ul ul {
  list-style-type: circle;
}

.collapse-expand-a .block .collapse-expand-cont .chevron {
  @include chevron-bullet();

  li {
    padding-bottom: 7px;
  }
}

.collapse-expand-a .block .collapse-expand-cont ul li a {
  &:not(.alternate-language-version-links__link) {
    @include font-medium();
    color: #414042;
    font-size: 16px;
    text-decoration: underline;
    padding-left: 10px;
  }
}

.collapse-expand-a .block .collapse-expand-cont > ul.with-bullet > li {
  @include font-content();
  display: block;
  font-size: 16px;
  padding-bottom: 7px;
  padding-left: 18px;
}

.collapse-expand-a .block .collapse-expand-cont > ul.with-bullet > li > a {
  background: none !important;
  padding-left: 0 !important;
  color: #414042;
  display: inline !important;
  text-decoration: underline;
  padding-left: 0;
}

.collapse-expand-a .block .collapse-expand-cont > ul.with-bullet > li > a:hover,
.collapse-expand-a
  .block
  .collapse-expand-cont
  > ul.with-bullet
  > li
  > a:focus {
  color: $color-purple;
  text-decoration: none;
}

.collapse-expand-a .block .collapse-expand-cont h3 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  padding-bottom: 12px;
  text-transform: uppercase;
}

ul.li-list > li {
  @include font-content();
  background: url("../images/arrow-light-grey1.png") no-repeat scroll 0 5px;
  display: block;
  font-size: 16px;
  padding-left: 18px;
  padding-bottom: 7px;
}

.float-box {
  @include font-medium();
  color: $color-purple;
  font-size: 20px;
  float: left;
  padding: 25px 10px 3px 0;
  margin-left: -40px;
  width: 197px;
}

.contact-list-container {
  border-bottom: 2px solid #000000;
  margin-bottom: 36px;
  padding-bottom: 30px;
  padding-top: 27px;
}

.special-topic.teamlist.contact-list {
  border-bottom: 2px solid #000000;
  padding-top: 27px;
  margin-bottom: 36px;
}

.special-topic.teamlist.contact-list h2 {
  padding-bottom: 15px;
}

.contact-list h2,
.subsection-list h2,
.location-det h2,
.search-media-form h2 {
  @include font-heading();
  color: $color-purple;
  font-size: 16px;
  line-height: 15px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.contact-list .thumbnail {
  float: left;
  width: 34%;
  margin-right: 4%;
}

.contact-list .thumbnail img {
  max-width: 100%;
}

.contact-list .thumbnail-detail {
  float: left;
  width: 62%;
}

.contact-list .thumbnail-detail h3 {
  @include font-heading();
  font-size: 18px;
  color: $color-black;
  text-transform: uppercase;

  a {
    display: block;
    line-height: 24px;
    min-height: 24px;
  }
}

.contact-list .thumbnail-detail h3 a:hover,
.contact-list .thumbnail-detail h3 a:focus {
  color: $color-purple;
}

.contact-list .thumbnail-detail h4 {
  font-size: 16px;
  line-height: 17px;
  font-family: HCoWhitney, arial;
  font-weight: 500;
  font-style: normal;
  color: #000;
  margin-top: 2px;

  @include for-print() {
    @include font(14px, 15px);
  }
}

.contact-list .thumbnail-detail div{
  margin-top: 4px
}
.contact-list .thumbnail-detail span,
.contact-list .thumbnail-detail span a {
  @include font-content();
  font-size: 14px;
  color: #000;
  display: inline-block;
  min-height: 24px;
}

.contact-list .thumbnail-detail span a:hover,
.contact-list .thumbnail-detail span a:focus {
  color: $color-purple;
}

.list-block {
  border-bottom: 0px solid #f2f2f2;
  padding: 8px 0 5px 0;
}

.list-block.no-border {
  border-bottom: 0px solid #f2f2f2;
}

.contact-list .show-more-link,
.person-depart-info .show-more-link {
  padding: 28px 0 5px 0;
}

.special-topic.teamlist.contact-list .show-more-link {
  padding: 4px 0 33px 0;
}

.subsection-list {
  border-bottom: 2px solid #000000;
  padding-bottom: 35px;
  margin-top: 20px;
  margin-bottom: 36px;
}

.subsection-list > ul {
  @include chevron-bullet();

  @include for-print() {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 17px;
  }
}

.subsection-list > ul > li {
  padding-bottom: 7px;

  &::marker {
    color: $color-purple-bullet;
    font-size: calc(105%);
  }
}

.subsection-list > ul > li.hide {
  display: none;
}

.subsection-list .show-more-link {
  padding: 25px 0 0px;
}

.related-source {
  margin-top: 36px;

  &__label {
    @include font-heading();
    color: $color-black;
    display: block;
    font-size: 18px;
    padding-bottom: 12px;
    text-transform: uppercase;
  }
}

.related-source h2 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.refine-result-info {
  padding-bottom: 24px;
}

.refine-result-info h2 {
  @include font-heading();
  color: $color-purple;
  font-size: 16px;
  padding-bottom: 11px;
  text-transform: uppercase;
}

.refine-result-info > ul > li {
  @include font-semibold();
  padding-bottom: 7px;
  color: #000;
}

.refine-result-info > ul > li > a {
  @include font-semibold();
  display: block;
}

.refine-result-info > ul > li > a span {
  color: $color-purple;
}

.refine-result-info > ul > li ul {
  margin-left: 30px;
}

.refine-result-info > ul > li ul li {
  padding-bottom: 7px;
}

.refine-result-info > ul > li ul li a {
  background: url("../images/arrow-light-grey.png") no-repeat 0 5px;
  display: block;
  padding-left: 18px;
}

.refine-result-info > ul > li ul li a:hover,
.refine-result-info > ul > li ul li a:focus {
  background-position: 0 -43px;
}

.selection-area {
  padding-bottom: 31px;
  display: none;
}

.selection-area h1 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.selectedFilterVal ul li {
  @include font-heading();
  background-color: #eeeeee;
  padding: 10px 22px 10px 13px;
  text-transform: uppercase;
  margin-bottom: 1px;
  position: relative;
}

.selectedFilterVal ul li span.crossBtn {
  background: url(../images/close-icon.gif) no-repeat 6px 6px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  float: right;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 21%;
  right: 0px;
  z-index: 99;
}

.clear-all-filter-block {
  padding-top: 14px;
}

.clear-all-filter {
  @include font-content();
  text-decoration: underline;
}

.aa h1,
.aa h3 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.aa .block {
  padding-bottom: 30px;
}

.aa .block .related-img {
  margin-top: 37px;
}

.aa .block .related-img img {
  max-width: 100%;
}

.layout-mobile .social-media-link-ls {
  display: inline-block;
  left: auto !important;
  right: 0px;
  width: auto;
  position: absolute;
  margin-left: 10px;
  margin-top: -45px;
}
/*.social-media-link-ls .st-custom-button  { margin-left: 0px!important; margin-right: 0px!important; } */
.social-media-link-ls .st-custom-button {
  /*padding-bottom: 7px;*/
  float: left;
}

.social-media-link-ls .st-custom-button a {
  background: url(/-/media/images/media-icon/social-media-icons.ashx) no-repeat
    0 0;
  display: block;
  float: left;
  height: 30px;
  overflow: hidden;
  text-indent: -999999px;
  width: 30px;
  margin-left: 10px;
}

.social-media-link-ls .sts-custom-button a {
  background: url(/-/media/images/media-icon/social-media-icons.ashx) no-repeat
    0 0;
  display: block;
  float: left;
  height: 30px;
  overflow: hidden;
  text-indent: -999999px;
  width: 30px;
}

.social-media-link-ls .st-custom-button[data-network="linkedin"] a {
  background-position: 0 0;
}

.social-media-link-ls .st-custom-button[data-network="youtube"] a {
  background-position: 0 -36px;
}

.social-media-link-ls .st-custom-button[data-network="twitter"] a {
  background-position: 0 -73px;
}

.social-media-link-ls .st-custom-button[data-network="facebook"] a {
  background-position: 0 -109px;
}

.social-media-link-ls .sts-custom-button a {
  background-position: 0 -145px;
}

.social-media-link-ls ul li.pdf a {
  background-position: 0 -182px;
}

.social-media-link-ls .st-custom-button[data-network="linkedin"] a:hover,
.social-media-link-ls .st-custom-button[data-network="linkedin"] a:focus {
  background-position: -43px 0;
}

.social-media-link-ls .st-custom-button[data-network="youtube"] a:hover,
.social-media-link-ls .st-custom-button[data-network="youtube"] e a:focus {
  background-position: -43px -36px;
}

.social-media-link-ls .st-custom-button[data-network="twitter"] a:hover,
.social-media-link-ls .st-custom-button[data-network="twitter"] a:focus {
  background-position: -43px -73px;
}

.social-media-link-ls .st-custom-button[data-network="facebook"] a:hover,
.social-media-link-ls .st-custom-button[data-network="facebook"] a:focus {
  background-position: -43px -109px;
}

.social-media-link-ls .sts-custom-button a:hover,
.social-media-link-ls .sts-custom-button a:focus {
  background-position: -43px -145px;
}

.social-media-link-ls ul li.pdf a:hover,
.social-media-link-ls ul li.pdf a:focus {
  background-position: -43px -182px;
}

h1.main-head {
  @include font-heading();
  font-size: 48px;
  line-height: 1;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.search-area {
  border-bottom: 2px solid #000;
  margin: 34px 0px 40px 0px;
  padding-bottom: 20px;
}

.search-area input[type="text"] {
  @include font-heading();
  color: $color-gray50;
  border: 2px solid #000;
  box-sizing: border-box;
  float: left;
  font-size: 16px;
  padding: 10px 12px;
  margin-right: 10px;
  text-transform: uppercase;
  width: 100%;
}

.search-area input[type="button"].btn-search {
  background: url("../images/icon-search.gif") no-repeat 0 0;
  border: none;
  cursor: pointer;
  float: left;
  height: 38px;
  margin-top: 2px;
  padding: 0;
  width: 38px;
  text-indent: -999999px;
}

.search-area input[type="button"].btn-search:hover {
  background: url("../images/icon-search-hover.gif") no-repeat 0 0;
}

.filter-search-head {
  padding-bottom: 27px;
  position: relative;
}

.result-found-res {
  display: none;
}

.filter-search-head .left,
.result-found-res {
  @include font-medium();
  float: left;
  color: $color-purple;
  font-size: 20px;
  line-height: 33px;
  padding-bottom: 10px;
  margin-right: 12px;
}

.filter-search-head .mid {
  float: left;
  line-height: 34px;
  margin-left: 0;
}

.filter-search-head .mid a {
  text-decoration: underline;
}

.filter-search-head .right {
  float: right;
}

.filter-search-head .right p,
.filter-search-head .right .sort-by {
  @include font-semibold();
  color: #000000;
  font-size: 16px;
}

.filter-search-head .right p a {
  @include font-content();
  font-size: 16px;
  text-decoration: underline;
  margin: 0 10px;
}

.filter-search-head .right p a.selected {
  @include font-semibold();
  text-decoration: none;
}

.filter-search-head .right p a:hover,
.filter-search-head .right p a:focus {
  color: $color-purple;
}

.filter-search-head ul.listing {
  border-top: 0px solid #000;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
}

.search-result-area {
  border-bottom: 7px solid #000000;
  padding-bottom: 40px;
}

.search-result-area .block {
  padding-bottom: 28px;
}

.search-result-area .block h2 {
  @include font-heading();
  font-size: 18px;
  line-height: 1;
  padding-bottom: 6px;
  text-transform: uppercase;
}

.search-result-area .block h2 a:hover,
.search-result-area .block h2 a:focus {
  color: $color-purple;
}

.search-result-area .block h2 a:hover span,
.search-result-area .block h2 a:focus span {
  color: $color-purple;
}

.search-result-area .block h2 a span {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
}

.search-result-area .block p {
  margin-bottom: 0;
}

.search-result-area .block p a {
  text-decoration: underline;
}

.search-result-area .block p a:hover,
.search-result-area .block p a:focus {
  color: $color-purple;
}

.search-result-area .block span {
  @include font-content();
  color: #7b7b7e;
  font-size: 14px;
  padding-top: 5px;
}

.search-result-area .block span span.pubType {
  @include font-semibold();
  color: #7b7b73;
  font-size: 16px;
}

.search-result-area .block p.author-name,
.search-result-area .block p span.author-name-eventResult {
  @include font-medium();
  font-size: 14px;
  margin-top: 3px;
}

.search-result-area .block p span.span-eventResult {
  font-size: 16px;
  color: #414042;
}

.search-result-area .block span.pubType {
  @include font-semibold();
  color: #7b7b73;
  font-size: 16px;
}

.pagination {
  padding: 19px 0 60px 0;
}

.pagination ul li {
  float: left;
  margin-right: 4px;
  margin-bottom: 5px;
}

.pagination ul li a {
  @include font-semibold();
  background-image: url(../images/pagination-icon.png);
  background-repeat: no-repeat;
  background-position: -88px 0;
  color: #000000;
  display: block;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  width: 24px;
  text-align: center;
}

.pagination ul li a.selected,
.pagination ul li a:hover,
.pagination ul li a:focus {
  background-position: -58px 0;
}

.pagination ul li a.first {
  background-position: -204px 0;
}

.pagination ul li a.last {
  background-position: -263px 0;
}

.pagination ul li a.next {
  background-position: -234px 0;
}

.pagination ul li a.prev {
  background-position: -175px 0;
}

.pub-search-area {
  display: none;
}

.pub-search-area .filter-search-head .left a {
  @include font-content();
  font-size: 16px;
  text-decoration: underline;
}

.pub-search-area .filter-search-head {
  padding-bottom: 30px;
}

.pub-search-area .result-found {
  padding-bottom: 20px;
}

.pub-search-area .pagination {
  padding: 19px 0 0px;
}

.pub-search-area .filter-search-head .right span.sort-by {
  float: left;
  width: 57px;
  line-height: 20px;
}

.pub-search-area .filter-search-head .right .main {
  float: left;
  height: 30px;
  width: 100%;
}

.pub-search-area .filter-search-head .right .main .stylish-select-right {
  padding: 5px 36px 5px 12px;
}

.pub-search-area .filter-search-head .right .main ul.listing {
  top: 30px;
}

.no-margin {
  margin: 0;
}

.form-section {
  border-bottom: 2px solid #000000;
  margin-bottom: 38px;
}

.breadcrumb-overlay {
  position: absolute;
  width: 100%;
  z-index: 13;
}
/* map end */

.address-block {
  padding: 10px 0;
}

.address-block .address {
  @include chevron-bullet();
}

.small-link {
  color: $color-purple;
  text-decoration: underline;
}

.margin-top {
  margin-top: 20px;
}

/* content wrapper end */

/* footer start */
#footer .row-1 {
  background-color: #d7d7d7;
  padding: 23px 0 23px 0;
}

#footer .row-1 .no-padding {
  @media (max-width: 834px) {
    display: flex;
    flex-direction: column;
  }
}

#footer .row-1 .footer-link {
  float: left;
  width: 67%;

  @media (max-width: 834px) {
    align-items: center;
    float: none;
    margin: auto;
    width: auto;
  }
}

#footer .row-1 .footer-link ul li {
  float: left;
  margin-right: 20px;
}

#footer .row-2 .copy-right-link ul li {
  margin: 0;
  display: inline-block;
  list-style: none;
}

#footer .row-2 .copy-right-link ul li:not(:first-child) {
  margin-left: 16px;
}

#footer .row-1 .footer-link ul li a {
  @include font-semibold();
  color: $color-black;
  font-size: 14px;
  line-height: 33px;
}

#footer .row-1 .footer-link ul li a:hover,
#footer .row-1 .footer-link ul li a:focus {
  color: $color-purple;
  text-decoration: underline;
}

#footer .row-1 .social-media-link {
  float: right;

  @media (max-width: 834px) {
    float: none;
    align-items: center;
    margin: 10px auto 0;
  }
}

#footer .row-1 .social-media-link ul li {
  float: left;
  margin-left: 12px;

  &:first-of-type {
    @media (max-width: 834px) {
      margin-left: 0;
    }
  }
}

#footer .row-1 .social-media-link ul li a {
  display: block;
  float: left;
  height: 30px;
  overflow: hidden;
  text-indent: -999999px;
  width: 30px;
  position: relative;
}

#footer .row-1 .social-media-link ul li a img {
  aspect-ratio: auto 1/1;
  float: left;
  width: 30px;
}

#footer .row-1 .social-media-link ul li a img.aa {
  aspect-ratio: auto 1/1;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 30px;
}

#footer .row-1 .social-media-link ul li:hover a img.aa,
#footer .row-1 .social-media-link ul li:focus a img.aa {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

#footer .row-1 .social-media-link ul li:hover a img,
#footer .row-1 .social-media-link ul li:focus a img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  filter: alpha(opacity=0);
}

#footer .row-2 {
  line-height: normal;
  padding: 5px 0;
}

#footer .row-2 .copy-right-link {
  float: left;

  @media (max-width: 834px) {
    text-align: center;
    width: 100%;
  }
}

#footer .row-2 .copy-right-link a {
  @include font-content();
  color: #000000;
  font-size: 12px;

  @media (max-width: 834px) {
    margin-right: 20px;
  }
}

#footer .row-2 .copy-right-link a:hover,
#footer .row-2 .copy-right-link a:focus {
  color: $color-purple;
  text-decoration: underline;
}

#footer .row-2 .copy-right-txt {
  float: right;

  @media (max-width: 834px) {
    width: 100%;
    text-align: center;
  }
}

#footer .row-2 .copy-right-txt p {
  @include font-content();
  color: #414042;
  font-size: 12px;
  line-height: 25px;
  margin: 0;
}

#footer .row-2 .copy-right-txt p.res-sp {
  display: none;
}
/* footer end */

/* tile class start */

/* for left col 620px start */
.container .col-l .tiles {
  margin-right: 3.225806451612903%;
}

.container .col-l .r1 {
  height: 260px;
}

.container .col-l .c1 {
  width: 48.38709677419355%;
}

.container .col-l .r2 {
  height: 520px;
}

.container .col-l .c1 {
  width: 48.38709677419355%;
}

.container .col-l .r3 {
  height: 780px;
}

.container .col-l .c2 {
  width: 100%;
}

/* for left col 620px end */

/* for right col 300px start */
.container .col-r .one-row-one-col {
  width: 100%;
  height: 260px;
}

.container .col-r .two-row-one-col {
  width: 100%;
  height: 520px;
}

.container .col-r .r1 {
  height: 260px;
}

.container .col-r .c1 {
  width: 100%;
}

.container .col-r .r2 {
  height: 520px;
}

.container .col-r .c1 {
  width: 100%;
}
/* for right col 300px end */

.tech-tile-wrapper {
  margin-top: 60px;
}

.video-block {
  height: 169px;
  position: relative;
}

.video-block > img {
  width: 100%;
  height: 100%;
}

.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 91px;
  height: 90px;
  margin-left: -45.5px;
  margin-top: -45px;
}

.tile-left {
  float: left;
  margin-right: 20px;
}

.tile-bg-left {
  float: left;
  margin-right: 0px !important;
}

.tile-right {
  float: right;
  margin-right: 0 !important;
}

.detail-page .col-l .tile-cont h2 {
  @include font-heading();
  color: $color-purple;
  font-size: 16px;
  line-height: 30px;
  padding-bottom: 18px;
  text-transform: uppercase;
}

.detail-page .col-l .tile-cont h4 {
  @include font-content();
  color: #231f20;
  font-size: 14px;
  text-transform: none;
}

/* tile class end */

/* carousel start */
#owl-example-outer-contaier {
  border-top: 7px solid #000000;
  border-bottom: 2px solid #000000;
  margin-bottom: 60px;
}

.owl-carousel-people .slide {
  height: 197px;
  padding-top: 30px;
}

.owl-carousel-people .owl-item {
  margin-right: 5px;
}

.owl-carousel-people h1 {
  @include font-heading();
  background: url(../images/heading-icons.png) no-repeat 0px 0px;
  color: $color-purple;
  font-size: 16px;
  line-height: 30px;
  padding-left: 40px;
  padding-bottom: 18px;
  text-transform: uppercase;
}

.owl-carousel-people h1.flashes {
  background-position: 0px 0px;
}

.owl-carousel-people h2 {
  @include font-heading();
  color: $color-black;
  font-size: 28px;
  line-height: 30px;
  text-transform: uppercase;
  padding-right: 123px;
}

.owl-carousel-people h2 a:hover,
.owl-carousel-people h2 a:focus {
  color: $color-purple;
}

.owl-carousel-people > .owl-controls {
  bottom: 5px;
  position: absolute;
  width: 100%;
}

.owl-carousel-people .owl-dot span {
  background: url(../images/carousel-dots.png) no-repeat 0px -29px;
  height: 14px;
  width: 14px;
}

.owl-carousel-people .owl-dot.active span,
.owl-carousel-people .owl-dot:hover span,
.owl-carousel-people .owl-dot:focus span {
  background: url(../images/carousel-dots.png) no-repeat 0px 0px;
}

.owl-carousel-people h3 {
  @include font-content();
  color: #231f20;
  font-size: 14px;
}

.one-col-layout #owl-example-outer-contaier {
  margin-bottom: 0px;
}
/* carousel end */

.perosn-detail {
  border-bottom: 2px solid #000000;
  padding-bottom: 20px;
  margin-bottom: 36px;
}

.perosn-detail .thumbnail {
  float: left;
  width: 160px;
  padding-bottom: 12px;
  height: 157px;
}

.perosn-detail .thumbnail img {
  max-width: 100%;
  max-height: 100%;
}

.perosn-detail .thumbnail-details {
  float: left;
  width: 100%;
}

.perosn-detail .thumbnail-details .off-add-cont .inner {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 10px;
}

.perosn-detail .thumbnail-details .off-add-cont .inner.last {
  border: 0;
  margin-bottom: 0px;
}

.perosn-detail .thumbnail-details,
.perosn-detail .thumbnail-details .off-add-cont .inner p {
  @include font-semibold();
  color: #000000;
}

.perosn-detail .thumbnail-details,
.perosn-detail .thumbnail-details .off-add-cont .inner p.off-add {
  @include font-content();
  color: #000000;
}

.perosn-detail .thumbnail-details,
.perosn-detail .thumbnail-details .off-add-cont .inner h4 {
  @include font-content();
}

.perosn-detail .thumbnail-details,
.perosn-detail .thumbnail-details .off-add-cont .inner h4.add-head {
  @include font-semibold();
}

.perosn-detail .thumbnail-details,
.perosn-detail .thumbnail-details .off-add-cont .inner h4.add-head a {
  text-decoration: none;
}

.perosn-detail .thumbnail-details,
.perosn-detail .thumbnail-details p span {
  @include font-content();
  color: #000000;
}

.perosn-detail .thumbnail-details a {
  @include font-semibold();
  word-break: break-all;
}

.perosn-detail .thumbnail-details a:hover,
.perosn-detail .thumbnail-details a:focus {
  color: $color-purple;
}

p.v-card {
  padding: 26px 0px 10px 0px;
}

p.v-card a {
  background: url(../images/v-card-icon.png) no-repeat 0px 0px;
  display: block;
  line-height: 24px;
  padding-left: 35px;
  text-decoration: none !important;
}

.person-depart-info-wrapper {
  border-bottom: 2px solid #000000;
  margin-bottom: 36px;
  padding-bottom: 4px;
}

.person-depart-info {
  padding: 0px 0px 26px 0px;
}

.person-depart-info h2 {
  @include font-heading();
  color: $color-purple;
  font-size: 16px;
  padding-bottom: 11px;
  text-transform: uppercase;
}

.person-depart-info > ul > li {
  padding-bottom: 7px;
  position: relative;
}

.person-depart-info > ul > li > a.audio {
  background: url(../images/icon-audio.png) no-repeat 0px 4px;
}

.person-depart-info > ul > li > a.audio:hover,
.person-depart-info > ul > li > a.audio:focus {
  background: url(../images/icon-audio-hover.png) no-repeat 0px 4px;
  color: $color-purple;
}

.person-depart-info > ul > li > a.video {
  background: url(../images/icon-video.png) no-repeat 0px 5px;
}

.person-depart-info > ul > li > a.video:hover,
.person-depart-info > ul > li > a.video:focus {
  background: url(../images/icon-video-hover.png) no-repeat 0px 5px;
  color: $color-purple;
}

.person-depart-info > ul > li > a.pdf {
  background: url(../images/icon-pdf.png) no-repeat 0px 2px;
}

.person-depart-info > ul > li > a.pdf:hover,
.person-depart-info > ul > li > a.pdf:focus {
  background: url(../images/icon-pdf.png) no-repeat 0px 2px;
  color: $color-purple;
}

.person-depart-info > ul > li ul {
  margin-left: 20px;
}

.person-depart-info > ul > li ul li a {
  font-size: 15px;
}

.person-depart-info > ul > li ul li a:hover,
.person-depart-info > ul > li ul li a:focus {
  color: $color-purple;
  text-decoration: underline;
}

.person-depart-info--border-bottom {
  border-bottom: 2px solid #000000;
  margin-bottom: 36px;
}

.person-heading {
  margin-left: 8.51063829787234%;
}

.person-heading > .person-heading-container {
  padding-bottom: 29px;
}

.person-heading h1 {
  @include font-heading();
  font-size: 36px;
  line-height: 1;
  text-transform: uppercase;
}

.person-heading h2 {
  @include font-heading();
  font-size: 24px;
  text-transform: uppercase;
}

.load-more {
  padding-bottom: 20px;
  text-align: center;
}

.load-more input[type="button"] {
  @include font-heading();
  background-color: #ffffff;
  border: 2px solid #000000;
  cursor: pointer;
  padding: 10px 104px;
  color: $color-black;
  text-transform: uppercase;
  -webkit-appearance: none;
  font-size: 16px;
}

.load-more input:hover,
.load-more input:focus {
  background-color: $color-white;
  border: 2px solid $color-white;
  color: $color-black;
}

.filter-tiles-wrapper {
  margin-top: 6px;
}

.filter-tiles-inner-wrapper {
  padding-top: 20px;
  margin: 0 auto;
  width: 165px;
}

#owl-example-outer-contaier.no-border {
  border: none;
}

#owl-carousel-tile-filter .owl-prev,
#owl-carousel-tile-filter .owl-next {
  background: #ffffff;
  margin-top: -36px;
  padding: 0px;
  width: 24px;
  height: 24px;
  text-indent: -999999px;
  overflow: hidden;
  background-image: url(../images/carousel-control.png);
  background-repeat: no-repeat;
}

#owl-carousel-tile-filter .owl-prev {
  float: left;
  margin-left: -35px;
  background-position: 7px 0px;
}

#owl-carousel-tile-filter .owl-next {
  float: right;
  margin-right: -33px;
  background-position: 6px -50px;
}

#owl-carousel-tile-filter .owl-item h2 {
  @include font-heading();
  color: $color-purple;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
}

#owl-carousel-tile-filter .owl-item h2 a {
  color: #000000;
  cursor: default;
  display: block;
}

#owl-carousel-tile-filter .owl-item h2 a.selected {
  color: $color-purple;
}

#owl-carousel-tile-filter .owl-item.active h2 a {
  color: $color-purple;
}

#owl-carousel-tile-filter .owl-controls .owl-nav {
  background: url(../images/carousel-control.png) no-repeat 0px 0px;
}

.hidden-cont {
  display: none;
}

.hidden-cont h2 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.hidden-cont img {
  max-width: 100%;
}

.responsive-filter {
  display: none;
}

.responsive-filter h2 {
  @include font-heading();
  background: url(../images/filter-arrow.gif) no-repeat 98% 20px #000000;
  color: $color-white;
  cursor: pointer;
  font-size: 16px;
  padding: 13px 10px;
  text-transform: uppercase;
}

.responsive-filter h2.expand {
  background: url(../images/filter-arrow-up.gif) no-repeat 98% 20px #000000;
}

.responsive-filter .filter-category {
  display: none;
}

.responsive-filter .filter-category ul li a {
  @include font-content();
  background-color: #000000;
  padding: 8px 10px;
  display: block;
  color: $color-white;
  font-size: 14px;
  border-bottom: 1px solid #393939;
}

.responsive-filter .filter-category ul li a:hover,
.responsive-filter .filter-category ul li a:focus {
  background-color: $color-purple;
}

.iframe-width {
  width: 560px;
  border: 0;
}

.iframe-width-flex {
  height: 165px;
  width: 100%;
  border: 0;
}

.video-block-device {
  display: none;
}

.bread-crumb {
  padding: 6px 0px 46px;
  float: left;
  width: 84%;
}

.bread-crumb ul li {
  @include font-semibold();
  float: left;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.35em;
  margin-right: 8px;
}

.bread-crumb ul li a {
  @include font-semibold();
  color: #ffffff;
  font-size: 14px;
}

.bread-crumb ul li a:hover,
.bread-crumb ul li a:focus {
  text-decoration: underline;
}

.main-heading h1 {
  @include font-heading();
  color: $color-white;
  font-size: 64px;
  line-height: 1;
  padding-bottom: 59px;
  padding-right: 250px;
  text-transform: uppercase;
}

.banner-content {
  width: 44.6809%;
  margin-left: 10px;
}

.banner-content h1 {
  @include font-heading();
  color: $color-purple;
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 18px;
  text-transform: uppercase;
}

.banner-content h2 {
  @include font-medium();
  color: $color-purple;
  font-size: 20px;
  line-height: 26px;
  margin-top: 59px;
}

/* for people page */
.person-detail-mob {
  border-bottom: 2px solid #000000;
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: -45px !important;
}

.container .layout-mobile .col-l {
  width: 100%;
}

.layout-mobile .perosn-detail .thumbnail {
  float: none;
}

.layout-mobile .person-heading {
  margin-left: 0;
  padding-bottom: 30px;
}

.person-detail-mob .perosn-detail {
  border-bottom: 0px solid #000000;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 45px;
}

.layout-mobile .person-heading {
  margin-left: 0;
  padding-bottom: 10px;
}

.person-detail-mob .perosn-detail .thumbnail-details {
  padding-bottom: 20px;
}

.person-detail-mob .people-intro p.v-card {
  padding: 0;
  margin: 0;
}

.person-detail-mob .people-intro .heading-brief {
  padding-bottom: 8px;
}

.layout-mobile .person-depart-info,
.layout-mobile .side-section-listing {
  padding: 0 0 30px;
}

.layout-mobile #owl-example-outer-contaier {
  margin-bottom: 20px;
  margin-top: 5px;
}

.layout-mobile .float-box {
  display: none;
}

.layout-mobile .collapse-expand-a .block .collapse-expand-cont {
  margin-top: 15px;
}

.layout-mobile .owl-carousel-people .slide {
  padding-top: 20px;
}

.layout-mobile .owl-carousel-people h2 {
  padding-right: 30px;
}

/* for technology page */
.layout-mobile .contact-list-container {
  border-bottom: 0px solid #000000;
  margin-bottom: 40px;
}

.layout-mobile .contact-list {
  background-color: #eeeeee;
  padding: 20px 20px 15px 20px;
}

.layout-mobile .contact-list .thumbnail {
  display: none;
}

.layout-mobile .contact-list .thumbnail-detail {
  width: 100%;
  padding-top: 0;
}

.layout-mobile .contact-list .thumbnail-detail p {
  @include font-heading();
}

.layout-mobile .contact-list h2 {
  padding-bottom: 6px;
}

.layout-mobile .contact-list .show-more-link {
  padding: 7px 0 0;
}

.layout-mobile .collapse-box .people-intro h1 {
  padding-bottom: 13px;
  line-height: 1;
}

.layout-mobile .show-more-link {
  padding: 25px 0 18px;
}

.layout-mobile .r3.c2 .tile-cont h3,
.r1.c2 .tile-cont h3 {
  padding-right: 0;
}

.layout-mobile .r2.c1 .tile-cont h3,
.layout-mobile .r1.c1 .tile-cont h3 {
  padding-right: 0;
}

.layout-mobile .person-depart-info-wrapper {
  padding: 0;
  margin-bottom: 36px;
}

.r3.c2 .tile-cont2 {
  height: 335px;
}

#careerOwlSlider {
  background-color: #000;
  margin-bottom: 15px;
  min-height: 278px;
}

.careerBox {
  padding: 36px 80px 40px;
  font-size: 16px;
  min-height: 202px;
}

.careerBox h2 {
  @include font-heading();
  color: $color-white;
  font-size: 28px;
  line-height: 30px;
  text-transform: uppercase;
  padding-right: 60px;
  margin-bottom: 14px;
}

.careerBox .content-area h3 {
  @include font-heading();
  color: $color-white;
  font-size: 20px;
  line-height: 30px;
  padding-right: 30px;
  text-transform: uppercase;
}

.careerBox .content-area span {
  @include font-content();
  color: $color-white;
  font-size: 14px;
}

.careerBox label {
  @include font-heading();
  color: $color-white;
  line-height: 45px;
  float: left;
  margin-right: 12px;
  text-transform: uppercase;
}

.careerBox .categoryList {
  float: right;
  width: 420px;
}

.categoryList .main ul.listing {
  max-height: 123px;
}

.careerBox .categoryList select,
.careerBox .categoryList input {
  @include font-heading();
  color: $color-black;
  font-size: 16px;
  padding: 11px;
  border-radius: 0;
  background-image: none;
  background-color: #fff;
  text-transform: uppercase;
  width: 100%;
  border: none;
}

.careerBox .categoryList input {
  width: 94%;
}

.careerBox .categoryList .customSelect .selectValueWrap .caret {
  cursor: pointer;
  position: absolute;
  right: 11px;
  top: 21px;
}

.careerBox .categoryList .customSelect .selectList {
  border-radius: 0;
}

.careerBox .categoryList .customSelect dd {
  padding: 10px;
  text-transform: uppercase;
}

.careerBox input.jobEnterBtn {
  @include font-heading();
  color: $color-white;
  float: right;
  background-color: #000;
  border: 2px solid #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 39px;
  text-transform: uppercase;
  -webkit-appearance: none;
  margin-top: 8px;
}

.careerBox input.jobEnterBtn:hover,
.careerBox input.jobEnterBtn:focus {
  background-color: $color-purple;
}

#careerOwlSlider.owl-theme .owl-controls {
  bottom: 10px;
  left: 80px;
  margin-top: 10px;
  position: absolute;
  text-align: center;
}

#careerOwlSlider.owl-theme .owl-dots .owl-dot span {
  background: none;
  display: block;
  margin: 5px;
  width: 16px;
  height: 15px;
  background-image: url(../images/dotBg.png);
  background-repeat: no-repeat;
  background-position: left -16px;
}

#careerOwlSlider.owl-theme .owl-dots .owl-dot.active span,
#careerOwlSlider.owl-theme .owl-dots .owl-dot:hover span,
#careerOwlSlider.owl-theme .owl-dots .owl-dot:focus span {
  margin: 5px;
  width: 16px;
  height: 15px;
  background: none;
  background-image: url(../images/dotBg.png);
  background-repeat: no-repeat;
  background-position: left 0px;
}

.peopSearSect {
  width: 780px;
  padding-bottom: 40px;
  border-bottom: #000 solid 7px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.peopSearSect h3.peSearHeading {
  @include font-medium();
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.alphaSearch {
  margin-bottom: 27px;
}

.alphaSearch > div {
  text-align: center;
}

.alphaSearch a {
  @include font-heading();
  font-size: 28px;
}

.alphaSearch a:hover,
.alphaSearch a:focus {
  color: $color-purple;
}

.alphaSearch a.selected {
  text-decoration: underline;
}

.alphaSearch a.inactive {
  color: #c8c8c8;
}

.alphaSearch a.inactive:hover,
.alphaSearch a.inactive:focus {
  text-decoration: none;
}

.alphaSearch.owl-theme .owl-controls {
  display: none;
}

.alphaSearch.owl-theme .owl-controls {
  margin-top: 0px;
}

.alphaSearch.owl-theme .owl-controls .owl-nav .owl-prev,
.alphaSearch.owl-theme .owl-controls .owl-nav .owl-next,
.alphaSearch.owl-theme .owl-controls .owl-nav [class*="owl-"]:hover,
.alphaSearch.owl-theme .owl-controls .owl-nav [class*="owl-"]:focus {
  background-repeat: no-repeat;
  background-position: 7px 6px;
  background-color: #ffffff;
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 0;
  padding: 0px;
  margin: 0px;
  position: absolute;
  top: 5px;
}

.alphaSearch.owl-theme .owl-controls .owl-nav .owl-prev,
.alphaSearch.owl-theme .owl-controls .owl-nav .owl-prev:hover,
.alphaSearch.owl-theme .owl-controls .owl-nav .owl-prev:focus {
  background-image: url(../images/prevBtn.jpg);
  left: -15px;
}

.alphaSearch.owl-theme .owl-controls .owl-nav .owl-next,
.alphaSearch.owl-theme .owl-controls .owl-nav .owl-next:hover,
.alphaSearch.owl-theme .owl-controls .owl-nav .owl-next:focus {
  background-image: url(../images/nextBtn.jpg);
  right: -15px;
}

.peopSearSect .oneHalf {
  width: 49.35%;
  margin-right: 1.3%;
  margin-bottom: 10px;
  float: left;
}

.peopSearSect .oneHalf.last {
  margin-right: 0px;
}

.peopSearSect .oneThird {
  width: 32.4%;
  margin-right: 1.3%;
  margin-bottom: 10px;
  float: left;
}

.peopSearSect .oneThird.last {
  margin-right: 0px;
}

.peopSearSect input,
.peopSearSect select,
.search-media-form input,
.search-media-form select,
.frmSearch .block-1 input[type="text"],
.frmSearch .block-2 input[type="text"],
.peopSearSect textarea {
  @include font-medium();
  border: #000 solid 2px;
  color: #7f7f7f;
  font-size: 16px;
  padding: 10px 12px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.peopSearSect input:focus,
.peopSearSect select:focus,
.search-media-form input:focus,
.search-media-form select:focus,
.frmSearch .block-1 input[type="text"]:focus,
.frmSearch .block-2 input[type="text"]:focus {
  color: #000000;
}

.frmSearch .block-1 input[type="text"],
.frmSearch .block-2 input[type="text"] {
  border: #000 solid 0px;
  margin: 0;
}

.peopSearSect input:focus {
  color: #000;
}

.peopSearSect input.nameField {
  margin-bottom: 34px;
}

.resetLink,
.resetLink2 {
  float: left;
  padding-top: 26px;
}

.resetLink a,
.resetLink2 a {
  text-decoration: underline;
}

.resetLink input[type="reset"],
.resetLink2 input[type="reset"] {
  @include font-content();
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  text-decoration: underline;
  text-transform: none;
  width: auto;
}

.resetLink2 {
  display: none;
}

.enterBtn {
  float: left;
  margin-top: 20px;
  width: 100%;
}

.enterBtn input {
  background-color: #000;
  padding: 9px 0px;
  width: 132px;
  text-align: center;
  color: #fff;
  float: left;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  cursor: pointer;
}

.enterBtn input[type="button"] {
  float: right;
}

.enterBtn input[type="reset"] {
  float: left;
  background-color: #ffffff;
  border: 2px solid #000000;
  color: #000000;
}

.enterBtn input:hover,
.enterBtn input:focus {
  background-color: $color-purple;
  color: $color-white;
  border: 2px solid $color-black;
}

.enterBtn input[type="reset"]:hover,
.enterBtn input[type="reset"]:focus {
  color: #ffffff;
  border: 2px solid #000000;
}

.moreOption {
  padding: 0 0 20px;
  display: none;
}

.moreOption a {
  @include font-heading();
  background: url(../images/plus-minus-icon.png) no-repeat 0px 3px;
  padding-left: 22px;
  color: $color-black;
  font-size: 16px;
  text-transform: uppercase;
}

.moreOption a.expand {
  background: url(../images/plus-minus-icon.png) no-repeat 0px -48px;
}

/* perople search result */
.people-search-result-wrapper {
  margin: 0 auto 25px;
  width: 82.97872340425532%;
  display: none;
}

.no-result-found {
  background: #eae7e7;
  padding: 20px 10px;
  display: none;
  margin: 0 auto;
  width: 82.9787%;
}

.no-result-found p {
  @include font-heading();
  font-size: 16px;
  text-transform: uppercase;
  margin: 0;
}

.people-search-result-inner-wraper {
  padding-bottom: 15px;
}

.people-search-result-inner-wraper ul li.bio-list-header {
  @include font-heading();
  border-bottom: 1px dotted #cccccc;
  font-size: 14px;
  text-transform: uppercase;
}

.people-search-result-inner-wraper ul li.bio-list-header div.m-image {
  height: auto;
}

.people-search-result-inner-wraper ul li {
  float: left;
  padding: 10px 0;
  width: 100%;
}

.people-search-result-inner-wraper ul li div.m-image,
.people-search-result-inner-wraper ul li div.details,
.people-search-result-inner-wraper ul li div.contact,
.people-search-result-inner-wraper ul li div.bio-links,
.people-search-result-inner-wraper ul li div.alternate-bio,
.people-search-result-inner-wraper ul li div.vcard {
  float: left;
}

.people-search-result-inner-wraper ul li div.m-image {
  height: 102px;
  width: 13%;
  margin-right: 1%;
  margin-left: 1%;
}

.people-search-result-inner-wraper ul li div.m-image img {
  width: 100%;
  max-height: 100%;
}

.people-search-result-inner-wraper ul li div.details {
  width: 29%;
  margin-right: 2%;
  margin-left: 1%;
}

.people-search-result-inner-wraper ul li div.details > ul > li {
  padding: 0;
}

.people-search-result-inner-wraper ul li div.details > ul > li a {
  font-size: 14px;
  text-decoration: underline;
}

.people-search-result-inner-wraper ul li div.details > ul > li a:hover,
.people-search-result-inner-wraper ul li div.details > ul > li a:focus {
  color: $color-purple;
}

.people-search-result-inner-wraper ul li div.details h3 {
  @include font-heading();
  font-size: 16px;
  font-size: 18px;
  text-transform: uppercase;
}

.people-search-result-inner-wraper ul li div.details h3 a:hover,
.people-search-result-inner-wraper ul li div.details h3 a:focus {
  color: $color-purple;
}

.people-search-result-inner-wraper ul li div.details span {
  font-size: 14px;
  font-style: italic;
}

.people-search-result-inner-wraper ul li div.details p {
  margin-bottom: 5px;
}

.people-search-result-inner-wraper ul li div.details p.dep {
  padding-top: 10px;
}

.people-search-result-inner-wraper ul li div.details p.country a {
  font-size: 14px;
  text-decoration: underline;
}

.people-search-result-inner-wraper ul li div.details p.country a:hover,
.people-search-result-inner-wraper ul li div.details p.country a:focus,
.people-search-result-inner-wraper ul li div.details p.dep a:hover,
.people-search-result-inner-wraper ul li div.details p.dep a:focus,
.people-search-result-inner-wraper ul li div.contact p a:hover,
.people-search-result-inner-wraper ul li div.contact p a:focus {
  color: $color-purple;
  text-decoration: none;
}

.people-search-result-inner-wraper ul li div.details p.position-loc {
  margin-bottom: 0px;
}

.people-search-result-inner-wraper
  ul
  li
  div.details
  p.position-loc
  span.mobileBioResult-loc,
.people-search-result-inner-wraper ul li div.details span.mobileBioResult-ph {
  display: none;
}

.people-search-result-inner-wraper
  ul
  li
  div.details
  p.position-loc
  span.mobileBioResult-loc
  a {
  color: #414042;
  font-style: normal;
}

.people-search-result-inner-wraper ul li div.details span.mobileBioResult-ph {
  font-style: normal;
}

.people-search-result-inner-wraper ul li div.contact {
  width: 30%;
  margin-right: 2%;
  margin-left: 1%;
}

.people-search-result-inner-wraper ul li div.contact p {
  margin-bottom: 0px;
}

.people-search-result-inner-wraper ul li div.contact p a {
  font-size: 14px;
  text-decoration: none;
}

.people-search-result-inner-wraper ul li div.contact p.mail-id {
  padding-bottom: 4px;
  line-height: 17px;
}

.people-search-result-inner-wraper ul li div.contact p.mail-id a {
  @include font-semibold();
}

.people-search-result-inner-wraper ul li div.contact > ul > li {
  padding: 0;
}

.people-search-result-inner-wraper ul li div.contact > ul > li a {
  color: #414042;
  font-size: 14px;
  text-decoration: none;
}

.people-search-result-inner-wraper ul li div.contact > ul > li a:hover,
.people-search-result-inner-wraper ul li div.details > ul > li a:focus {
  color: $color-purple;
}

.people-search-result-inner-wraper ul li div.vcard {
  width: 20%;
}

.people-search-result-inner-wraper ul li div.vcard p {
  margin: 0;
  padding: 0;
}

.people-search-result-inner-wraper ul li div.vcard p.phone {
  padding-top: 23px;
  padding-bottom: 30px;
  padding-right: 10px;
  text-align: right;
}

.people-search-result-inner-wraper ul li div.vcard p.phone a {
  color: #414042;
}

.people-search-result-inner-wraper ul li div.vcard p.phone a:hover {
  color: $color-purple;
}

.people-search-result-inner-wraper ul li div.vcard p.v-card a {
  @include font-semibold();
}

.people-search-result-inner-wraper ul li div.bio-links {
  width: 20%;
  margin-right: 2%;
  margin-left: 1%;
}

.people-search-result-inner-wraper ul li div.alternate-bio {
  width: 17%;
}

.people-search-result-inner-wraper ul li div.bio-links ul li,
.people-search-result-inner-wraper ul li div.alternate-bio ul li {
  padding: 0;
}

.people-search-result-inner-wraper ul li div.bio-links ul li a,
.people-search-result-inner-wraper ul li div.alternate-bio ul li a {
  color: $color-purple;
  font-size: 14px;
  text-decoration: none;
}

.people-search-result-inner-wraper ul li div.bio-links ul li a:hover,
.people-search-result-inner-wraper ul li div.bio-links ul li a:focus,
.people-search-result-inner-wraper ul li div.alternate-bio ul li a:hover,
.people-search-result-inner-wraper ul li div.alternate-bio ul li a:focus {
  text-decoration: underline;
}

.people-search-result-inner-wraper ul li.bg-light-grey,
#globalsearchresult1 > ul > div > li.bg-light-grey {
  background-color: #f7f7f7;
}

.people-search-result-wrapper .pagination {
  padding-bottom: 0;
}

.people-search-result-wrapper .people-search-result-inner-wraper {
  border-bottom: 7px solid #000;
  padding-bottom: 40px;
}

.people-search-result-wrapper .customSelect .selectedValue,
.pub-search-area .customSelect .selectedValue {
  @include font-heading();
  border: 2px solid #000;
  background: none repeat scroll 0 0 #ffffff;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-transform: uppercase;
}

.people-search-result-wrapper .customSelect .selectValueWrap .caret,
.pub-search-area .customSelect .selectValueWrap .caret {
  top: 14px;
}

.people-search-result-wrapper .customSelect .selectList,
.pub-search-area .customSelect .selectList {
  border: medium none;
  border-radius: 0;
  margin-top: 1px;
}

.people-search-result-wrapper .customSelect,
.pub-search-area .customSelect {
  @include font-heading();
  text-transform: uppercase;
  margin-right: 0;
}

.people-search-result-wrapper .customSelect dd,
.pub-search-area .customSelect dd {
  padding: 5px 10px 5px 8px;
}

.people-search-result-wrapper .customSelect .selected,
.pub-search-area .customSelect .selected {
  background-color: $color-purple;
  background-image: none;
  color: #ffffff;
}

.filter-search-head .right span.sort-by {
  float: left;
  margin: 6px 5px 0 0;
  display: block;
}

/*select style*/
.filter-search-head .right .sortSel {
  width: 140px;
  float: left;
}

.filter-search-head .right .sortSel .main {
  height: 31px;
}

.filter-search-head .right .sortSel .main .stylish-select-right {
  height: 21px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-search-head .right .sortSel .main ul.listing {
  top: 31px;
}

.careerBox .video-block {
  height: 130px;
}

.careerBox .alumniDropdown .categoryList {
  width: 352px;
}

/* Publication form */
.form-publication-section {
  border-bottom: 2px solid #000000;
  margin-bottom: 38px;
  padding: 34px 0 40px;
}

.form-publication-section input[type="text"] {
  @include font-heading();
  border: #000 solid 2px;
  color: #7f7f7f;
  font-size: 16px;
  padding: 10px 12px;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-bottom: 10px;
}

.form-publication-section input[type="text"]#start_date-search,
.form-publication-section input[type="text"]#end_date-search {
  margin-right: 5px;
  width: 91%;
}

.form-publication-section input[type="text"]:focus {
  color: #000;
}

.form-publication-section img.ui-datepicker-trigger,
.search-media-form img.ui-datepicker-trigger {
  float: right;
}

.fewerOption {
  padding: 26px 0 20px;
}

.fewerOption a {
  @include font-heading();
  background: url(../images/plus-minus-icon.png) no-repeat 0px 3px;
  padding-left: 22px;
  color: $color-black;
  font-size: 16px;
  text-transform: uppercase;
}

.fewerOption a.expand {
  background: url(../images/plus-minus-icon.png) no-repeat 0px -48px;
}

.hiddenFewerForm {
  display: none;
}

.pubReset {
  float: left;
  padding-top: 16px;
}

.pubSearchBtn input {
  @include font-heading();
  background-color: #000;
  border: 2px solid #000000;
  padding: 9px 0px;
  width: 120px;
  text-align: center;
  font-size: 16px;
  color: $color-white;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  cursor: pointer;
  text-transform: uppercase;
}

.pubSearchBtn input:focus {
  color: #fff;
}

.pubSearchBtn input:hover,
.pubSearchBtn input:focus {
  background-color: $color-white;
  border: 2px solid $color-white;
}

.pubSearchBtn input[type="reset"] {
  float: left;
  background-color: #ffffff;
  border: 2px solid #000000;
  color: #000000;
}

.pubSearchBtn input[type="reset"]:hover {
  background-color: $color-purple;
  color: #ffffff;
}

.pubSearchBtn input[type="submit"] {
  float: right;
}

.pubSearchBtn input[type="button"] {
  float: right;
}

.nameLabel {
  width: 70%;
  float: left;
}

.nameLabel span {
  position: absolute;
  top: 16px;
  right: 14px;
}

.nameLabel span a {
  width: 12px;
  height: 12px;
  display: block;
  background-image: url(../images/close-icon.gif);
  background-repeat: no-repeat;
}

.pubYearDropdown {
  width: 26%;
  float: right;
}

.globalDropdown {
  padding-bottom: 10px;
}

.pubYearDropdown select,
.globalDropdown select {
  @include font-heading();
  border: #000 solid 2px;
  color: $color-gray50;
  font-size: 16px;
  padding: 10px 12px;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.few-option {
  padding-bottom: 22px;
}

.publicationTypes {
  padding-bottom: 22px;
  padding-top: 4px;
}

.publicationTypes h3 {
  font-size: 16px;
  margin-bottom: 20px;
}

.cleTypes {
  padding-bottom: 20px;
}

.publicationTypes .checkbox,
.cleTypes .checkbox {
  float: left;
  margin-right: 7px;
  height: 20px;
  width: 20px;
  clear: left;
  float: left;
  background-image: url(../images/checkbox.png);
  background-repeat: no-repeat;
  background-position: left 0;
  cursor: default;
  text-align: left;
  position: relative;
  z-index: 2;
}

.publicationTypes label,
.cleTypes label {
  @include font-heading();
  color: $color-black;
  float: left;
  font-size: 16px;
  text-transform: uppercase;
  width: 78%;
  z-index: 1;
}

.publicSelectAll {
  width: 30%;
  float: left;
  position: relative;
}

.publicationCheckOpt {
  width: 70%;
  float: right;
}

.publicationCheckOpt ul li {
  list-style: none;
  display: inline;
  float: left;
  width: 50%;
  margin-bottom: 18px;
  position: relative;
}

.publicationCheckOpt.full-width ul li {
  width: 100%;
}

.pubHiddenArea .publicationTypes label,
.cleTypes label {
  width: 85%;
}

.pubHiddenArea .publicSelectAll {
  width: 100%;
  margin-bottom: 18px;
}

.pubHiddenArea .publicationCheckOpt {
  width: 50%;
  float: left;
}

.pubHiddenArea .publicationCheckOpt ul li {
  width: 100%;
}

.checkbox input,
.radio input {
  opacity: 0;
  filter: alpha(opacity=0);
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
}

.checkbox input.show,
.radio input.show {
  display: inline;
}

.selected {
  background-position: 0 -20px;
}

img[src="http://maps.gstatic.com/mapfiles/api-3/images/google_white2.png"], a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

.add-clear-span a {
  color: #000000 !important;
  right: 10px !important;
}

.left {
  float: left;
}

.btn-area {
  padding-bottom: 30px;
}

input.globalBtn,
.btn-area a.globalBtn {
  background-color: #000;
  border: medium none;
  color: #fff;
  cursor: pointer;
  padding: 13px 0;
  text-align: center;
  width: 132px;
}

.btn-area a.globalBtn {
  padding: 12px 20px;
  display: block;
}

.btn-area a.globalBtn:hover,
.btn-area a.globalBtn:focus {
  background-color: $color-purple;
  color: #fff;
}

input.globalBtn:hover,
input.globalBtn:focus {
  background-color: $color-purple;
}

.location-det {
  border-bottom: 2px solid #000000;
  padding-bottom: 20px;
}

.location-det p a {
  text-decoration: underline;
}

.location-det p a:hover,
.location-det p a:focus {
  color: $color-purple;
}

.responsive-nav .padding10 ul.nav-main-link > li {
  position: relative;
}

.responsive-nav .padding10 ul.nav-main-link > li span.respSubNavArrow {
  border: 0px solid #f00;
  background: url(../images/responsive-nav-arrow.png) no-repeat 79% -48px;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 12px;
  top: 9px;
  cursor: pointer;
}

.responsive-nav .padding10 ul.nav-main-link > li span.respSubNavArrow.expand {
  background-position: 79% 12px;
}

/* form section start */
.thankyou-wrapper,
.frm-register-wrapper {
  padding: 20px 20px 0px 20px;
}

.frm-register-wrapper .block-fl {
  margin-bottom: 10px;
}

.frm-register-wrapper .block-hf {
  float: left;
  width: 48%;
  margin-right: 2%;
  margin-bottom: 10px;
}

.frm-register-wrapper .block-flinput[type="text"],
.frm-register-wrapper .block-hf input[type="text"] {
  @include font-heading();
  border: 2px solid #000;
  box-sizing: border-box;
  color: $color-gray50;
  font-size: 16px;
  padding: 10px 12px;
  text-transform: uppercase;
  width: 100%;
}

.header-info {
  padding-bottom: 10px;
}

.header-info h1 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.frmCheckOpt.full-width ul li {
  width: 100%;
}

.frmCheckOpt ul li {
  display: inline;
  float: left;
  list-style: outside none none;
  margin-bottom: 5px;
  position: relative;
  width: 50%;
}

.frmCheckOpt .radiobtn {
  float: left;
  width: 20px;
}

.frmCheckOpt label {
  @include font-heading();
  color: $color-black;
  font-size: 16px;
  padding-left: 5px;
  text-transform: uppercase;
  width: 100%;
}

.frm-register-wrapper .btn-area {
  padding: 0;
  text-align: right;
}

.frm-register-wrapper .btn-area input[type="button"],
.thankyou-wrapper .btn-area input[type="button"] {
  margin-left: 10px;
  width: auto;
  padding: 13px 20px;
}

.thankyou-wrapper {
  display: none;
}

.thankyou-wrapper .btn-area input[type="button"] {
  margin-left: 0px;
}

/* t&c start */
.term-condition-wrapper {
  padding-top: 20px;
}

.term-condition-wrapper h2 {
  @include font-heading();
  color: #010101;
  font-size: 32px;
  line-height: 1;
  padding-bottom: 6px;
  text-transform: uppercase;
}

.term-condition-wrapper .collapse-expand-a {
  margin-top: 20px;
  padding-bottom: 20px;
}

.term-condition-wrapper .collapse-expand-a .block {
  padding-bottom: 20px;
}

.term-condition-wrapper .collapse-expand-a .block h2 {
  font-size: 22px;
}

.location-det-ls.location-det {
  display: none;
}

/* error page */
.error-wrapper {
  width: 53.19148936170213%;
  margin: 0 auto;
}

.error-wrapper h1 {
  @include font-heading();
  color: $color-purple;
  font-size: 80px;
  line-height: 1;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.error-wrapper h1.er5 {
  font-size: 35px;
}

.error-wrapper h2 {
  @include font-heading();
  color: $color-black;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.error-wrapper p {
  @include font-medium();
  color: #414042;
  margin-bottom: 16px;
}

.error-wrapper p a {
  color: $color-purple;
}

.error-wrapper p a:hover,
.error-wrapper p a:focus {
  text-decoration: underline;
}

.error-wrapper .btn-area {
  text-align: center;
  margin-top: 20px;
}

.error-wrapper .btn-area input[type="button"] {
  background-color: #000;
  border: medium none;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
  padding: 13px 0;
  text-align: center;
  width: 132px;
}

.error-wrapper .btn-area input[type="button"]:hover,
.error-wrapper .btn-area input[type="button"]:focus {
  background-color: $color-purple;
}

/* Accessibility page */
#scrollableutility {
  position: absolute;
  text-align: center;
  left: -100%;
  top: 0;
  height: 0;
  z-index: 0;
  overflow: hidden;
}

#scrollableutility ul li {
  float: left;
}

#scrollableutility ul li a {
  @include font-content();
  color: #000000;
  float: left;
  font-size: 12px;
  padding: 0 5px;
  display: block;
}

#scrollableutility ul li a:hover,
#scrollableutility ul li a:focus {
  text-decoration: underline;
}

.visuallyhidden {
  border: 0 none;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.internal-pages .print-head {
  background: $color-purple;
  border-bottom: 3px solid #000000;
  height: 40px;
}

.internal-pages .print-head .container .left {
  float: left;
  position: relative;
}

.internal-pages .print-head .container .right {
  float: right;
  padding-top: 8px;
}

.internal-pages .print-head .container .right p {
  @include font-heading();
  color: $color-white;
  font-size: 16px;
  margin: 0;
  line-height: 26px;
  text-transform: uppercase;
}

.internal-pages .print-wrapper .logo-wrapper {
  text-align: center;
}

.internal-pages .collapse-expand-a {
  margin-top: 30px;
}

.internal-pages .collapse-expand-a .block h2 {
  background: none;
  padding-left: 0;
}

.internal-pages .collapse-expand-a .block .collapse-expand-cont {
  padding-left: 0;
}

.internal-pages .logo-wrapper {
  margin-top: 3px;
}

/* media */
.media-box {
  margin: 0 3.225806451612903%;
  border: 0;
}

.search-media-form {
  border-bottom: 7px solid #000;
  margin: 0 8.5% 60px 8.5%;
  padding-bottom: 40px;
}

.block-wrapper .block {
  padding-bottom: 10px;
}

.block-wrapper .block.half-col {
  float: left;
  width: 49%;
  margin-right: 2%;
}

.block-wrapper .block.half-col.last {
  margin-right: 0;
}

.block-wrapper .block.half-col span {
  float: left;
}

.block-wrapper .block.half-col input,
.block-wrapper .block.half-col select {
  width: 100%;
  float: left;
  margin-right: 5px;
}

.news-wrapper {
  padding-bottom: 20px;
  padding-top: 25px;
}

.news-wrapper a:hover,
.news-wrapper a:focus {
  color: $color-purple;
  text-decoration: underline;
}

.news-wrapper h2 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.news-wrapper .article-block {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 10px;
}

.news-wrapper .article-block a {
  @include font-heading();
  display: block;
  font-size: 15px;
  padding-bottom: 2px;
  text-transform: uppercase;
}

.news-wrapper .article-block a span {
  color: $color-purple;
}

.news-wrapper .article-block a:hover,
.news-wrapper .article-block a:focus {
  color: $color-purple;
}

.media-result-wrapper {
  margin: 0 8.5% 25px 8.5%;
  display: none;
}

.media-result-inner-wraper {
  border-bottom: 7px solid #000;
  padding-bottom: 40px;
}

.media-result-inner-wraper ul li {
  float: left;
  width: 100%;
  padding: 10px 0;
}

.media-result-inner-wraper ul li.media-list-header {
  @include font-heading();
  border-bottom: 1px dotted #cccccc;
  font-size: 14px;
  text-transform: uppercase;
}

.media-result-inner-wraper ul li.bg-light-grey {
  background-color: #f7f7f7;
}

.media-result-inner-wraper ul li div.date,
.media-result-inner-wraper ul li div.title,
.media-result-inner-wraper ul li div.description {
  float: left;
}

.media-result-inner-wraper ul li div.date {
  margin-left: 1%;
  margin-right: 2%;
  width: 17%;
}

.media-result-inner-wraper ul li div.title {
  margin-left: 1%;
  margin-right: 2%;
  width: 35%;
}

.media-result-inner-wraper ul li div.title a {
  font-size: 14px;
}

.media-result-inner-wraper ul li div.title a.newspdf {
  background: url("../images/icon-pdf.png") no-repeat scroll 0 2px;
  padding-left: 18px;
  display: block;
  color: #414042;
}

.media-result-inner-wraper ul li div.title a:hover,
.media-result-inner-wraper ul li div.title a:focus {
  color: $color-purple;
  text-decoration: underline;
}

.media-result-inner-wraper ul li div.description {
  width: 42%;
}

.media-resource-wrapper {
  margin: 0 8.5% 20px;
}

.media-resource-wrapper h2 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.media-resource-wrapper ul {
  margin-left: 20px;
  padding-bottom: 10px;
}

.media-resource-wrapper ul li {
  background: url("../images/arrow-light-grey1.png") no-repeat scroll 0 5px
    rgba(0, 0, 0, 0);
  color: #414042;
  display: block;
  padding-left: 18px;
}

.media-resource-wrapper ul li:hover,
.media-resource-wrapper ul li:focus {
  background: url("../images/arrow-light-grey1-active.png") no-repeat scroll 0
    5px;
}

.two-eqcol-layout {
}

.two-eqcol-layout .block {
  float: left;
  margin-right: 2%;
  width: 49%;
}

.two-eqcol-layout .block.last {
  margin-right: 0;
}

.media-resource-wrapper .address-block p.phone span,
.media-resource-wrapper .address-block p.fax span {
  @include font-semibold();
}

.media-resource-wrapper .address-block h3 {
  @include font-heading();
  color: $color-black;
  font-size: 16px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.owl-carousel-ourfirm .slide {
  height: 250px;
}

/* date picker start */
#start_date-search,
#end_date-search {
  width: 90%;
}

img.ui-datepicker-trigger {
  cursor: pointer;
  margin-top: 5px;
}

.ui-datepicker .ui-datepicker-next:hover,
.ui-datepicker .ui-datepicker-next:focus,
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-prev:focus {
  background: none !important;
}

.first-sel .main {
  z-index: 4;
}

.content-area img {
  max-width: 100%;
  height: 150px;
}

.txt-purple p {
  @include font-medium();
  color: $color-purple;
  font-size: 16px;
  line-height: 25px !important;
  padding-bottom: 0px !important;
}

.result-found {
  @include font-medium();
  color: $color-purple;
  font-size: 20px;
}

/* Start SharingTools */
.st-custom-button:hover {
  cursor: pointer;
}

.st-custom-button {
  background: url(/-/media/images/media-icon/social-media-icons.ashx) no-repeat
    0 0 !important;
  height: 30px !important;
  width: 30px !important;

  &:focus-visible {
    @include focus-outline();
  }
}

.st-custom-button-pdf {
  background: none;
  border: none;

  &:focus-visible {
    @include focus-outline();
  }
}

.st-custom-button[data-network="linkedin"] {
  margin-bottom: 12px;
}

.st-custom-button[data-network="linkedin"] {
  background-position: 0 0 !important;
  background: none;
  border: none;
}

.st-custom-button[data-network="linkedin"]:hover,
.st-custom-button[data-network="linkedin"]:focus {
  background-position: -43px 0 !important;
}

.st-custom-button[data-network="twitter"] {
  margin-bottom: 12px;
}

.st-custom-button[data-network="twitter"] {
  background-position: 0 -73px !important;
  background: none;
  border: none;
}

.st-custom-button[data-network="twitter"]:hover,
.st-custom-button[data-network="twitter"]:focus {
  background-position: -43px -73px !important;
}

.st-custom-button[data-network="facebook"] {
  margin-bottom: 12px;
}

.st-custom-button[data-network="facebook"] {
  background-position: 0 -109px !important;
  background: none;
  border: none;
}

.st-custom-button[data-network="facebook"]:hover,
.st-custom-button[data-network="facebook"]:focus {
  background-position: -43px -109px !important;
}

.layout-mobile
  .person-detail-mob
  ~ .social-media-link-ls
  .sts-custom-button-email {
  margin-top: 45px;
}

.sts-custom-button-email {
  background: url(/-/media/images/media-icon/social-media-icons.ashx) no-repeat
    0 -220px !important;
  height: 30px !important;
  width: 30px !important;
  margin-bottom: 12px;
  float: left;
}

.sts-custom-button-email:hover,
.sts-custom-button-email:focus {
  background: url(/-/media/images/media-icon/social-media-icons.ashx) no-repeat -43px -220px !important;
  height: 30px !important;
  width: 30px !important;
  outline: none;
}

.sts-custom-button-email:focus-visible {
  @include focus-outline();
}

.sts-custom-button {
  background: url(/-/media/images/media-icon/social-media-icons.ashx) no-repeat
    0 -220px !important;
  height: 30px !important;
  width: 30px !important;
  margin-bottom: 12px;
}

.sts-custom-button {
  background-position: 0 -145px !important;
  float: left;
  background: none;
  border: none;

  &:focus-visible {
    @include focus-outline();
  }
}

.sts-custom-button:hover,
.sts-custom-button:focus {
  background-position: -43px -145px !important;
}
/* End SharingTools */

.owl-carousel-people.owl-theme .owl-dots .owl-dot span {
  background: none;
  margin: 5px;
  width: 14px;
  height: 14px;
  background-image: url(../images/carousel-dots.png);
  background-repeat: no-repeat;
  background-position: left -29px;
}

.owl-carousel-people.owl-theme .owl-dots .owl-dot.active span,
.owl-carousel-people.owl-theme .owl-dots .owl-dot:hover span {
  background-image: url(../images/carousel-dots.png);
  background-position: 0 0;
}

.underline-n {
  text-decoration: underline;
}

.layout-mobile .underline {
  text-decoration: none;
}

p.underline > a {
  text-decoration: none;
}

.search-result-ajax {
  @include font-content();
  color: #414042;
  font-size: 16px;
  margin-bottom: 0px;
}

.email {
  background: url("../images/icon-email.gif") no-repeat 0 6px;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  text-decoration: none !important;
  width: 16px;
  margin-left: 5px;
}

#divContactUs h2 {
  @include font-heading();
  color: $color-purple;
  font-size: 16px;
  line-height: 1;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.captField {
  float: left;
  width: 98.5%;
}

.captField span.captcha_field {
  background-color: #efefef;
  border-color: #000;
  border-radius: 0;
  border-style: solid;
  border-width: 2px 0 2px 2px;
  box-sizing: border-box;
  float: left;
  height: 44px;
  padding: 5px 10px;
  text-align: center;
  width: 22%;
}

.captField input {
  border-left: 0 none;
  border-radius: 0;
  float: left;
  margin-bottom: 0;
  width: 78%;
}

.capchaBox .refreshBtn {
  float: left;
  margin-top: 10px;
}

.summarytext {
  @include font-medium();
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

#divContactUs .fullRow {
  margin-bottom: 10px;
}

.test {
  float: left;
}

.test:focus {
  outline: 1px dotted #fff;
}

.disclaimer {
  font-size: 12px;
  color: #999;
}

/* after script disable */
.noscript {
  position: absolute;
  background: url(../images/overlay_bg-s.png) repeat;
  left: 0;
  top: 0;
  width: 100%;
  height: 2500px;
  z-index: 10000;
}

.noscript div {
  padding: 38px 30px;
  width: 500px;
  margin: 0 0 0 -250px;
  position: fixed;
  left: 50%;
  top: 30%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 17px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.noscript div p {
  @include font-content();
  padding: 0 0 27px;
}

.noscript div a.btn {
  @include font-heading();
  background: #000000;
  display: inline-block;
  color: $color-white;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 26px;
  height: 26px;
  padding: 0px 20px;
}

.noscript div a.btn:hover {
  background: $color-purple;
}

.ui-front {
  z-index: 1000 !important;
}

.pubsortddl .main {
  width: 100% !important;
}

.pubsortddl .main ul.listing li {
  line-height: 16px !important;
  padding: 7px 10px !important;
}

.field-validation-error,
.RequiredField,
#captchaError {
  color: red;
  font-size: 12px;
}

.RequiredField {
  display: none;
}

a.refreshBtn {
  cursor: pointer;
}

.collapse-expand-cont p a {
  color: #414042 !important;
}

#searchHeader.filter-search-head .mid {
  margin-left: 7%;
}

.global-search-sorting {
  float: left;
  padding-bottom: 10px;
  padding-top: 5px;
  width: 200px;
}

.global-search-sorting .main {
  height: 31px;
  z-index: 5;
}

.global-search-sorting .main ul.listing {
  top: 31px;
}

.global-search-sorting .sort-by,
.global-search-sorting-per-page .sort-by {
  @include font-semibold();
  color: #000000;
  font-size: 16px;
  display: block;
  padding-bottom: 2px;
}

.global-search-sorting .sortSel .main .stylish-select-right {
  height: 21px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.global-search-sorting ul.listing {
  border-top: 0px solid #000;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
}

.global-search-sorting ul.listing li {
  padding: 5px 10px;
  line-height: 18px;
}

.global-search-nest .filter-search-head {
  padding-bottom: 19px;
}

/* time zone table */
table.tbl-date-detail {
  margin-bottom: 15px;
}

table.tbl-date-detail tr td {
}

table.tbl-date-detail tr td p {
  margin: 0;
  padding: 0;
}

.datetime-zone {
  @include font-semibold();
}

/* for top landing pages */
.top-level-cont {
  padding-bottom: 18px;
  border-bottom: #000 solid 7px;
  margin: 0 8.5% 30px;
}

.top-level-cont .top-level-two-col {
  padding-bottom: 25px;
}

.top-level-cont .top-level-two-col .col-l {
  float: left;
  margin-right: 3.91%;
  width: 37%;
}

.top-level-cont .top-level-two-col .col-r {
  float: left;
  width: 59%;
  margin-right: 0;
}

.top-level-cont .top-level-two-col .col-l p {
  @include font-medium();
  color: $color-purple;
  font-size: 26px;
  line-height: 29px;
  padding-bottom: 20px;
}

.top-level-cont .top-level-two-col #owl-example-outer-contaier {
  margin-bottom: 0;
}

.top-level-cont .top-level-three-col .cols {
  float: left;
  width: 28%;
  margin-right: 7.5%;
}

.top-level-cont .top-level-three-col .cols.last {
  margin-right: 0;
}

.top-level-cont .top-level-three-col .cols .block {
  padding-bottom: 19px;
}

.top-level-cont .top-level-three-col .cols .block h2 {
  @include font-heading();
  color: $color-black;
  font-size: 28px;
  line-height: 1;
  padding-bottom: 23px;
  text-transform: uppercase;
}

.top-level-cont .top-level-three-col .cols .block .block-inner {
  padding-bottom: 13px;
}

.top-level-cont .top-level-three-col .cols .block .block-inner,
.top-level-cont .top-level-three-col .cols .block .block-inner p {
  font-size: 14px;
  line-height: 16px;
}

.top-level-cont .top-level-three-col .cols .block .block-inner p.head-txt {
  font-size: 16px;
  margin: 0px 0px 3px 0px;
}

.top-level-cont .top-level-three-col .cols .block .block-inner p.head-txt a {
  @include font-semibold();
  color: $color-purple;
  font-size: 16px;
}

.top-level-cont .top-level-three-col .cols .block p a.see-all {
  @include font-semibold();
  background: url("../images/arrow-light-grey.png") no-repeat 0 5px;
  font-size: 14px;
  line-height: 20px;
  padding-left: 15px;
  text-decoration: none;
}

.top-level-cont .top-level-three-col .cols .block p a.see-all:hover,
.top-level-cont .top-level-three-col .cols .block p a.see-all:focus {
  background-position: 0 -43px;
  color: #000;
}

.top-level-cont .top-level-three-col .cols .block .block-inner p.date-loc {
  font-size: 14px;
}

.layout-desktop .lp-inner-block {
  display: block;
}

/* Search result UI start */
.search-result-area ul li {
  float: left;
  padding: 10px 0;
  width: 100%;
}

.search-result-area ul li.bg-light-grey {
  background-color: #f7f7f7;
}

.search-result-area ul li div.m-image,
.search-result-area ul li div.details,
.search-result-area ul li div.vcard {
  float: left;
}

.search-result-area ul li div.m-image {
  height: 102px;
  width: 18%;
  margin-right: 1%;
  margin-left: 1%;
}

.search-result-area ul li div.m-image img {
  max-height: 100%;
  width: 100%;
}

.search-result-area ul li div.details {
  width: 35%;
  margin-right: 2%;
  margin-left: 1%;
}

.search-result-area ul li div.details h3 {
  @include font-heading();
  font-size: 16px;
  font-size: 18px;
  text-transform: uppercase;
}

.search-result-area ul li div.details span.position-sr {
  @include font-heading();
  color: $color-black;
  font-size: 14px;
  text-transform: uppercase;
}

.search-result-area ul li div.details p.dep {
  font-size: 14px;
  padding-top: 0px;
  margin-bottom: 0;
}

ul.loc-contact li {
  color: #000;
  padding: 0;
  font-size: 14px;
}

ul.loc-contact li span {
  @include font-semibold();
  margin-right: 5px;
}

.search-result-area ul li div.vcard {
  width: 42%;
}

.search-result-area ul li div.vcard p {
  margin: 0;
  padding: 0;
}

.search-result-area ul li div.vcard p.mail-id {
  line-height: 17px;
  margin-bottom: 7px;
  margin-top: 23px;
  font-size: 14px;
}

.search-result-area ul li div.vcard p.mail-id a {
  @include font-semibold();
  text-decoration: underline;
}

.search-result-area ul li div.vcard p.v-card a {
  @include font-semibold();
}

/* realted people link under accordians */
.subGroupRelatedPeople a {
  @include font-semibold();
  padding-left: 17px;
  background-image: url(/Contents/images/arrow-light-grey1.png);
  background-repeat: no-repeat;
  background-position: left 6px;
  display: inline-block;
  text-decoration: none !important;
  color: #000 !important;
}

.subGroupRelatedPeople a:hover {
  background-image: url(/Contents/images/arrow-light-grey1-active.png);
  color: $color-purple !important;
}

#sectionSubCategory ul#ulPersonFacets li span.subCat {
  cursor: pointer;
  display: block;
}

#sectionSubCategory ul#ulPersonFacets li ul.subCatItem {
  margin-left: 26px;
  padding-top: 5px;
}

#sectionSubCategory ul#ulPersonFacets li ul.subCatItem li a {
  background: none;
  padding-left: 0;
}

a.pagingViewAllBtn {
  width: auto !important;
  background: none !important;
  border: 2px solid #cccccc;
  border-radius: 16px;
  padding: 0px 10px;
  font-size: 11px !important;
  height: auto !important;
  line-height: 19px !important;
}

a.pagingViewAllBtn:hover {
  border: 2px solid #000000;
}

#divContactUs .oneHalf .main {
  width: 96%;
  float: left;
}

.mandatory {
  color: red;
  float: left;
  margin: 0px 0px 0px 3px;
}

#divContactUs .oneThird input[type="text"] {
  width: 96%;
}

#divContactUs input[type="text"],
#divContactUs select {
  @include font-medium();
  float: left;
  width: 97%;
  text-transform: capitalize;
}

#divContactUs input[type="text"]::placeholder {
  @include font-medium();
  text-transform: capitalize;
}

#divContactUs input[type="text"] {
  margin: 0;
}

#divContactUs textarea {
  float: left;
  width: 98.5%;
  color: #000000;
  border-radius: 0;
}

#divContactUs .captField input[type="text"] {
  width: 73%;
  margin-right: 1%;
}

#divContactUs .RequiredField {
  float: left;
}

#divContactUs .validation-summary {
  padding-bottom: 15px;
}

#divContactUs .mandatory-mess {
  font-size: 14px;
}

#divContactUs .mandatory-mess span {
  color: red;
  font-family: Arial;
}

.filter-search-head .r-left {
  float: left;
}

.right-block {
  float: right;
}

.filter-search-head .right-most {
  float: left;
  margin-left: 10px;
}

.filter-search-head .right-most span.sort-by {
  display: block;
  float: left;
  margin: 6px 5px 0 0;
}

.filter-search-head .right-most .sortSel .main {
  height: 31px;
}

.filter-search-head .right-most .sortSel {
  float: left;
  width: 90px;
}

.event-search-result-wrapper .filter-search-head .right-most .sortSel {
  width: 75px;
}
/* New Events Page */
.filter-search-head .right-most .sortSel .main .stylish-select-right {
  height: 21px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.filter-search-head .right-most p,
.filter-search-head .right-most .sort-by {
  @include font-semibold();
  color: #000;
  font-size: 16px;
}

.filter-search-head .right-most .sortSel .main ul.listing {
  top: 31px;
}

.left-block {
  float: left;
}

.pub-search-area .right-block,
.pub-search-area .left-block {
  float: left;
  width: 100%;
}

.pub-search-area .filter-search-head .mid {
  float: right;
}

.pub-search-area .filter-search-head .right-most {
  float: right;
}

.pub-search-area ul.listing li {
  line-height: 18px;
  padding: 5px 10px;
}

.event-search-result-wrapper .pub-search-area ul.listing li {
  font-size: 14px;
}
/* New Events Page */

.global-search-sorting-per-page {
  float: left;
  padding-bottom: 20px;
  padding-top: 5px;
  width: 200px;
}

.global-search-sorting-per-page .sortSel {
  width: 55%;
}

.global-search-sorting-per-page .main {
  height: 31px;
  z-index: 4;
}

.global-search-sorting-per-page .sortSel .main .stylish-select-right {
  height: 21px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.global-search-sorting-per-page ul.listing {
  border-top: 0px solid #000000;
  top: 31px;
}

.resRefreshBtn {
  float: left !important;
  margin-left: 8px;
  display: none;
}

#globalsearchresult1 > ul#divPeopleListing li {
  float: left;
  padding: 10px 0;
  width: 100%;
}

#globalsearchresult1 > ul#divPeopleListing li div.m-image,
#globalsearchresult1 > ul#divPeopleListing li div.details,
#globalsearchresult1 > ul#divPeopleListing li div.vcard {
  float: left;
}

#globalsearchresult1 > ul#divPeopleListing li div.m-image {
  height: 102px;
  width: 17%;
  margin-right: 1%;
  margin-left: 1%;
}

#globalsearchresult1 > ul#divPeopleListing li div.m-image img {
  width: 100%;
  max-height: 100%;
}

#globalsearchresult1 > ul#divPeopleListing li div.details {
  width: 40%;
  margin-right: 2%;
  margin-left: 1%;
}

#globalsearchresult1 > ul#divPeopleListing li div.vcard {
  width: 38%;
  padding-top: 28px;
}

#globalsearchresult1 > ul#divPeopleListing li div.details h3 {
  @include font-heading();
  font-size: 16px;
  font-size: 18px;
  margin-bottom: 3px;
  text-transform: uppercase;
}

#globalsearchresult1 > ul#divPeopleListing li div.details h3 a:hover,
#globalsearchresult1 > ul#divPeopleListing li div.details h3 a:focus {
  color: $color-purple;
}

#globalsearchresult1 > ul#divPeopleListing li div.vcard p.v-card {
  padding: 0;
}

#globalsearchresult1 > ul#divPeopleListing li div.vcard p.v-card a,
#globalsearchresult1 > ul#divPeopleListing li div.vcard p.mail-id a,
.mobileBioResul#divPeopleListingt-loc {
  @include font-semibold();
}

#globalsearchresult1 > ul#divPeopleListing li div.vcard p.mail-id a {
  font-size: 14px;
  text-decoration: underline;
  white-space: nowrap;
}

.perosn-detail p.bio-mail-id a {
  white-space: nowrap;
}

#globalsearchresult1 > ul#divPeopleListing li div.details p.position-loc {
  @include font-heading();
  color: $color-black;
  font-size: 14px;
  margin-bottom: 1px;
  text-transform: uppercase;
}

#globalsearchresult1 > ul#divPeopleListing li div.details p.loc-ph {
  font-size: 14px;
  margin-bottom: 0;
}

.frmNewPeoSearch {
  border-bottom: 2px solid #000;
  margin: 20px 0 40px;
}

.mobileBioResult-loc {
  @include font-semibold();
  margin-right: 4px;
}

.global-search-sorting .stylish-select-right,
.global-search-sorting-per-page .stylish-select-right,
.filter-search-head .stylish-select-right,
.categoryList .stylish-select-right {
  color: #000000;
}

.filter-search-head .right .sortSel.newsSortDropdown {
  width: 151px;
}

.pub-search-area .filter-search-head .right .sortSel {
  width: 142px;
}

.event-search-result-wrapper
  .pub-search-area
  .filter-search-head
  .right
  .sortSel {
  width: 180px;
}
/* New Events Page */
#globalsearchresult1 > ul#divPeopleListing li div.vcard p.mail-id {
  line-height: 14px;
  margin-bottom: 13px;
}

.address-block p.phone a,
.address-block p.fax a {
  @include font-semibold();
  font-size: 16px;
  text-decoration: none !important;
}

.phone-in-cont {
  @include font-semibold();
  text-decoration: none !important;
}

.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: 0 !important;
}

.people-intro-ld > h1 {
  padding-bottom: 0 !important;
}

/*Sitemap*/

.site-map ul > li.first-level {
  margin-bottom: 31px;
  padding-bottom: 0px; /*background: url(../images/arrow-black.gif) no-repeat 0 5px;padding-left: 20px;*/
  border: 0px solid red;
}

.site-map ul > li > a {
  @include font-semibold();
  text-transform: capitalize;
  font-size: 24px;
}

.site-map ul > li > ul > li > a.sec-level-sm {
  font-size: 19px;
}

.site-map ul > li > ul > li > ul > li > a.third-level-sm {
  text-transform: capitalize;
  font-size: 14px;
}

.site-map h1.main-head {
  padding-bottom: 30px;
}

.site-map ul > li > ul.first-levelul {
  margin-top: 9px;
  border: 0px solid red;
}

.site-map ul > li > ul.first-levelul > li {
  padding-bottom: 10px;
}

.site-map ul > li > ul > li > ul > li > a.third-level-sm + ul {
  margin: 0px 0 0px 0;
  border: 0px solid red;
  margin-top: 10px;
}

.site-map ul > li > ul > li > ul > li > a.third-level-sm + ul > li {
  margin-bottom: 0px;
  padding-bottom: 12px;
}

.site-map ul > li > ul > li > ul > li > a.third-level-sm + ul > li:last-child {
  padding-bottom: 2px;
}

.site-map ul > li > ul > li > ul > li > a.third-level-sm + ul > li > a {
  @include font-content();
  color: #000;
  font-size: 14px;
  background: url("../images/arrow-light-grey1.png") no-repeat 0 5px;
  padding-left: 18px;
}

.site-map ul > li > ul > li > ul > li > a.third-level-sm + ul > li > a:hover {
  color: $color-purple;
}

.site-map ul > li.first-level li {
  padding: 0px 0 0px 0;
}

.site-map ul > li > ul > li > a.sec-level-sm + ul {
  margin-bottom: 0px;
  margin-top: 10px;
}

.site-map ul > li > ul > li > a.sec-level-sm + ul > li {
  margin-bottom: 0px;
  padding-bottom: 11px;
}

.site-map ul > li > ul > li > a.sec-level-sm + ul > li:last-child {
  padding-bottom: 1px;
}
/*.site-map ul > li > ul.first-levelul > li{border-bottom: 1px solid #d1d1d1;}
.site-map ul > li > ul.first-levelul > li:last-child{ border-bottom: none;}*/

/*Sitemap*/

.layout-mobile .people-intro {
  padding-bottom: 4px;
}

.purple-para .heading-brief {
  margin-bottom: 30px;
}

.collapse-box .people-intro .show-more-link1 {
  padding-bottom: 7px;
}

.layout-mobile .subsection-list {
  padding-bottom: 16px;
  margin-bottom: 34px;
  margin-top: 45px;
}

#nav-wrapper span.mob-colon {
  display: none;
}
/*.ui-widget-content {
  max-height: 235px;
  overflow-y: auto;
  overflow-x: hidden;
}*/

/*.term-and-condiotion > .collapse-expand-a .block{ padding-bottom: 8px; }
.term-and-condiotion > .collapse-expand-a .block h2{ background: url(/Contents/images/icon-accordian-arrow-sm.png) no-repeat 0px 5px; font-size: 18px; padding-left: 30px; }
.term-and-condiotion > .collapse-expand-a .block h2.expand{ background: url(/Contents/images/icon-accordian-arrow-sm-dn.png) no-repeat 0px 5px; }
.term-and-condiotion > .collapse-expand-a .block .collapse-expand-cont{ padding-left: 28px; margin-top: 10px; }
.term-and-condiotion > .collapse-expand-a .block h2:hover, .term-and-condiotion > .collapse-expand-a .block h2:focus{ background: url(/Contents/images/icon-accordian-arrow-sm-dn.png) no-repeat 0px 5px }*/

.black {
  color: #000000 !important;
}

.grey {
  color: #7f7f7f !important;
}

.stylish-select-left.focus {
  outline: 1px dotted #cccccc;
}

/*Three digit Paging*/
.pagination ul li a.fontSizeTag {
  font-size: 10px;
}
/*end Paging*/

.stylish-select-left.focus {
  outline: 1px dotted #cccccc;
}

.no-text-msg {
  display: none;
  color: #414042;
  font-style: italic;
  font-size: 15px;
  line-height: 16px;
}

.stopScrol {
  position: fixed;
  width: 100%;
}

.mail-id-image a,
.bio-mail-id-image a {
  @include font-semibold();
  background: url(../images/email_iconbios.jpg) no-repeat 0px 50%;
  padding-left: 35px;
}

.detail-page .col-l h4 {
  @include font-heading();
  font-size: 14px;
  color: $color-black;
  margin-top: 12px;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.detail-page .col-l h1 {
  @include font-heading();
  font-size: 36px;
  line-height: 40px;
  color: $color-black;
  text-transform: uppercase;
  padding-bottom: 0px;
}

.ui-autocomplete {
  max-height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
}

.autocomplete-width {
  max-height: 300px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.curPointer {
  cursor: pointer;
}

.ui-menu-item {
  cursor: pointer;
}

.detail-page .location-det h2 {
  color: $color-purple;
  font-size: 16px;
}

.ui-datepicker {
  z-index: 2 !important;
}

/*removed !important for IPAD at 768 MLCOM-747 */
.bioSideBar .no-border .social-media-link-ls {
  top: -94px;
  width: 30px;
  left: 0px;
}

.bioSideBar .no-border .social-media-link-ls:hover {
  cursor: pointer;
}

/*Custom 26/04*/

.search-result-area .block h2 a span > span.highlightTxt {
  @include font-heading();
  background-color: #b0e6fc;
  font-size: 18px;
  padding-top: 0px;
}

.search-result-area .block p > span.highlightTxt {
  @include font-content();
  background-color: #b0e6fc;
  font-size: 16px;
  padding-top: 0px;
}

blockquote {
  padding-left: 14px !important;
  padding-right: 16px !important;
}

.purple-para h3 {
  margin-top: 30px;
  margin-bottom: 12px;
}

.purple-para > ul li a {
  color: #000;
  text-decoration: underline;
}

.purple-para > ul li a:hover {
  color: $color-purple;
  text-decoration: none;
}

/* Font Snippets*/

.whitney-medium-font {
  @include font-medium();
}

.whitney-book-font {
  @include font-content();
}

.whitney-semibold-font {
  @include font-semibold();
}

.futura_exboldcond_font {
  @include font-heading();
}

/* BIOS Language Selector */

.dropbtn {
  background-image: url("../images/language-selection_icon.png");
  padding: 16px;
  border: none;
  cursor: pointer;
  background-repeat: no-repeat;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 3;
  position: relative;
  margin-left: 10px;
  padding-bottom: 20px;
}

.language-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  border: black;
  border-style: solid;
  border-width: 2px;
  background-color: white;
  position: relative;
  min-width: 150px;
  width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  margin-top: -15px;
  padding-top: 20px;
}

.dropdown-content a {
  color: black;
  padding: 2px 0 2px 0;
  text-decoration: none;
  display: block;
}

.language-dropdown:hover .dropbtn {
  background-color: transparent;
  width: 100%;
}

.language-dropdown .dropdown-content a.language {
  @include font-heading();
  background-image: none;
  padding-left: 5px;
  width: 145px;
  padding-top: 5px;
  padding-bottom: 3px;
  height: 18px;
  text-indent: 0px;
  font-size: 14px;
  color: #666;
  line-height: 1;
}

.language-dropdown .dropdown-content a.language.japanese {
  @include font-heading();
}

.language-dropdown .dropdown-content a.language.english {
  @include font-heading();
}

.language-dropdown .dropdown-content a.language.chinese {
  font-family: $font-simplified-chinese-content;
  font-weight: bold;
}

.language-dropdown:hover {
  background-color: transparent;
}

.language-dropdown .dropdown-content a.language:hover {
  background-color: $color-purple;
  color: #fff;
}

/* Language Selector Bios - Mobile*/
.layout-mobile .person-detail-mob ~ .social-media-link-ls {
  display: block;
  left: auto;
  right: 0;
  width: auto;
}

.layout-mobile
  .person-detail-mob
  ~ .social-media-link-ls
  .st-custom-button[data-network="facebook"],
.layout-mobile
  .person-detail-mob
  ~ .social-media-link-ls
  .st-custom-button[data-network="twitter"],
.layout-mobile
  .person-detail-mob
  ~ .social-media-link-ls
  .st-custom-button[data-network="linkedin"],
.layout-mobile
  .person-detail-mob
  ~ .social-media-link-ls
  .st-custom-button[data-network="email"] {
  width: 30px;
  margin-top: 45px;
}

.layout-mobile .person-detail-mob ~ .social-media-link-ls .sts-custom-button {
  display: none;
}

.layout-mobile
  .person-detail-mob
  ~ .social-media-link-ls
  .language-dropdown
  .dropdown-content {
  right: 6%;
}

/* Alternate Bios - Third Column*/
.bio-version-urls {
  float: right;
  display: inline-block;
  width: 25%;
  padding-right: 10px;
}

.bio-version-urls a {
  @include font-content();
  font-size: 14px;
  color: $color-purple;
  text-decoration: none;
}

.bio-version-urls a:hover {
  text-decoration: underline;
}

.bio-version-urls ul li {
  padding: 0;
}
/* Alternate Bios - Third Column End*/

/* MultiLanguage - Auto Detect - Start */

#search-wrapper .col-1 input[type="text"].ja,
#search-wrapper .col-2 input[type="button"].ja {
  @include font-content();
}

#nav-wrapper ul.nav-main-link > li > a.ja {
  @include font-content();
}

#nav-wrapper ul.nav-main-link > li > .sub-menu .col-1 ul.level-1 > li a.ja,
#nav-wrapper
  ul.nav-main-link
  > li
  > .sub-menu
  .col-1
  ul.level-1
  > li
  ul.level-2
  > li
  > a.ja {
  @include font-heading();
}

#nav-wrapper ul.nav-main-link li.selectedMenu > a.ja {
  @include font-semibold();
}

.sec-level-sub-menu .col-block ul li a.ja {
  @include font-medium();
}

.person-depart-info > ul > li > a.ja {
  @include font-semibold();
}

.subsection-list > ul > li > a.ja {
  @include font-semibold();
}

.subsection-list > ul > li > a.ja {
  @include font-semibold();
}

.top-level-cont .top-level-three-col .cols .block .block-inner.ja p.head-txt a {
  @include font-semibold();
}

.top-level-cont .top-level-three-col .cols .block .block-inner.ja {
  @include font-content();
}

.top-level-cont .top-level-three-col .cols .block .block-inner.ja p {
  @include font-content();
}

.contact-list .thumbnail-detail h3.ja {
  @include font-bold();
}

.contact-list .thumbnail-detail p.ja {
  @include font-bold();
}

.person-depart-info > ul > li > a.ja {
  @include font-semibold();
}

.address-with-map .left .address-block.ja {
  @include font-content();
}

.login-lang-wrapper ul li a.ja {
  @include font-content();
}

.print-wrapper .add-block-p.ja {
  @include font-content();
}

#footer .row-1 .footer-link ul li a.ja {
  @include font-heading();
}

#footer .row-2 .copy-right-link a.ja {
  @include font-content();
}

.collapse-expand-a .block.ja h2,
.collapse-expand-a .block.ja h2.expand {
  @include font-heading();
}

.collapse-expand-a .block.en h2 {
  @include font-heading();
}

.collapse-expand-a .block h2.ja,
.collapse-expand-a .block h2.expand.ja {
  @include font-heading();
}

.collapse-expand-a .block.ja .collapse-expand-cont p,
.collapse-expand-a .block.ja .collapse-expand-cont ul li {
  @include font-content();
}

.collapse-expand-a .block.ja .collapse-expand-cont h3 {
  @include font-heading();
}

.show-more-link1.ja a {
  @include font-heading();
}

.keycontact-print span.print-participant {
  @include font-semibold();
  color: #000;
  margin-right: 0;
  text-transform: none;
}

.keycontact-print span.print-participant.ja {
  @include font-semibold();
  color: #000;
  margin-right: 0;
}

.print-wrapper .content-wrapper .collapse-expand-a ul > li > a.ja {
  @include font-content();
}

.print-wrapper .collapse-expand-a .print-extent-p h2.ja {
  @include font-semibold();
}

.print-wrapper .collapse-expand-a .block h2.ja,
.print-wrapper .collapse-expand-a .block h2.expand.ja {
  @include font-semibold();
}

.print-wrapper .collapse-expand-a .block.ja .collapse-expand-cont p {
  @include font-content();
}

.print-wrapper .collapse-expand-a .block.ja .collapse-expand-cont h3 {
  @include font-semibold();
}

.print-wrapper .collapse-expand-a .block.ja .collapse-expand-cont ul li {
  @include font-content();
}

.print-wrapper .collapse-expand-a .block.ja .collapse-expand-cont p {
  @include font-content();
}

.print-wrapper .collapse-expand-a .block.en .collapse-expand-cont p {
  @include font-content();
}

.print-wrapper .show-more-link1.ja a {
  @include font-content();
}

.collapse-box .people-intro span.small-head-next span.wb,
.search-result-area .block span span.wb,
.search-result-area .block p span.span-eventResult span.wb,
.news-wrapper .article-block a span.date span.wb,
.collapse-expand-cont p a span.date span.wb,
.collapse-expand-a .block .collapse-expand-cont p span.date span.wb {
  @include font-content();
}

.datetime-zone span.wb {
  @include font-semibold();
}

/* Multilinguagal Title - Start */
.search-result-area .block h2 a span.ja,
.search-result-area .block h2 a span.ja span,
.detail-page .col-l h1.small-head span.ja {
  @include font-heading();
}

.print-wrapper .detail-page .col-l h1.small-head span.ja {
  @include font-semibold();
}

.search-result-area .block h2 a span.zh-cn,
.search-result-area .block h2 a span.zh-cn span,
.detail-page .col-l h1.small-head span.zh-cn,
.blog-intro .blogpost-heading h3 a span.zh-cn {
  font-family: $font-simplified-chinese-content;
  font-weight: bold;
}

.postContainerHolder h3 a span.ja {
  @include font-semibold();
  font-size: 16px;
  line-height: 20px;
}

.print-wrapper .postContainerHolder h3 a span.ja {
  @include font-semibold();
}

.collapse-expand-cont p a span.zh-cn,
.postContainerHolder h3 a span.zh-cn {
  font-family: $font-simplified-chinese-content;
}

.collapse-expand-cont p a span.ja {
  @include font-content();
}

.print-wrapper .collapse-expand-cont p a span.ja {
  @include font-content();
}

.detail-page .col-l h1.small-head span {
  @include font-heading();
}

.detail-page .col-l h1.small-head span.multilang-title:not(.default-lang) {
  font-size: 18px;
  line-height: 1.4;
  display: block;
}
/* Multilinguagal Title - End */

/* MultiLanguage - Auto Detect - End */

/* Events Search Update Start */
#eventsResult #event-search-tabs {
  margin-top: -2.4em;
  padding: 0;
}

#eventsResult .ui-corner-all,
#eventsResult .ui-corner-top {
  border-radius: 0;
}

#eventsResult .ui-corner-top {
  left: 12px;
}

#eventsResult .ui-widget-content {
  box-shadow: none;
}

#eventsResult .ui-widget-header {
  background: none;
  background-color: #d7d7d7;
  border: none;
  padding-top: 12px;
}

#eventsResult .ui-widget-header.has-disabled-tab {
  background-color: #eaeaea;
}

#eventsResult .ui-state-default {
  background: none;
  border: none;
}

/*#eventsResult #event-search-tabs ul.ui-tabs-nav{
	padding: 0;
}*/

/*#eventsResult #event-search-tabs ul li.ui-state-default {
	border: none;
    width: 50%;
    margin: 0;
    padding: 0;
}*/

#eventsResult #event-search-tabs ul li.ui-state-hover {
  /*background-color: #000000 !important;*/
  background-color: #eaeaea !important;
}

#eventsResult #event-search-tabs ul li.ui-state-hover.ui-state-active,
#eventsResult #event-search-tabs ul li.ui-state-default.ui-state-active,
#eventsResult #event-search-tabs ul li.ui-state-focus {
  background-color: #fff !important;
}

#eventsResult #event-search-tabs ul li.ui-state-hover.ui-state-active a,
#eventsResult #event-search-tabs ul li.ui-state-focus a {
  color: #000000 !important;
}

#eventsResult #event-search-tabs ul li.ui-state-hover a {
  color: $color-purple;
}

#eventsResult #event-search-tabs ul li a {
  @include font-semibold();
  text-transform: uppercase;
  outline: none;
  /*width: 100%;*/
}

/*#eventsResult #event-search-tabs ul li.ui-state-default:nth-child(2) :not(.ui-state-active) {
	background: none; 
}*/

#eventsResult .ui-tabs-panel {
  padding: 1em 0 0 0;
}

#eventsResult #event-search-tabs .right .styledSelect {
  font-size: 13px;
  line-height: 23px;
}

/*#eventsResult .ui-state-default, #eventsResult .ui-widget-content .ui-state-default, #eventsResult .ui-widget-header .ui-state-default {
        background: #e6e6e6;
}*/

@media only screen and (max-width: 480px) {
  #eventsResult #event-search-tabs {
    margin-top: -1.4em;
  }
}

@media only screen and (max-width: 620px) {
  #eventsResult #event-search-tabs ul li a {
    font-size: 12px;
  }
}

/*@media only screen and (max-width : 720px) {
    #eventsResult #event-search-tabs .right .styledSelect {
        font-size: 12px;
    }

    #eventsResult #event-search-tabs .right-most .styledSelect {
        font-size: 13px;
    }

    //#eventsResult #event-search-tabs ul li.ui-state-default {
    //    width: 48%;
    //}

    #eventsResult #event-search-tabs .right-most .sortSel {
        width: 116px;
    }

    #eventsResult #event-search-tabs .right .sortSel {
        width: 165px;
    }

    #eventsResult #event-search-tabs .right-most .sort-by {
        line-height: 20px;
    }
}*/

@media only screen and (max-width: 960px) {
  #eventsResult .pub-search-area .right,
  #eventsResult .pub-search-area .right .sort-by,
  #eventsResult .pub-search-area .right .sortSel,
  #eventsResult .pub-search-area .right-most,
  #eventsResult .pub-search-area .right-most .sort-by,
  #eventsResult .pub-search-area .right-most .sortSel {
    width: 100%;
  }

  #eventsResult .pub-search-area .right .sortSel .main {
    width: 99%;
  }

  #eventsResult .pub-search-area .right-most {
    padding-top: 7px;
  }

  #eventsResult .pub-search-area .right-most .sortSel .main,
  #eventsResult .pub-search-area .right-most .sortSel .main .select {
    z-index: auto;
  }
}
/* Events Search Update End */
/* Event series link back - Start */

.c-event-series__link--title {
  @include font-heading();
  font-size: 18px;
  color: $color-black;
  text-transform: uppercase;
  word-wrap: break-word;
}

.c-event-series__link--title:hover {
  color: $color-purple;
}

/* Event series link back - End */

/*Event media links - Start*/

.c-eventml__title,
.c-eventml__media-item,
.c-eventml__video-url {
  text-decoration: underline;
}

.c-eventml__link a.c-eventml__title {
  padding: 0 0 8px 0;
  display: inline-block;
}

.c-eventml__link {
  padding: 8px 0 0 0;
  display: block;

  &:hover {
    color: $color-purple;
    text-decoration: none;
  }

  @include for-print() {
    display: none;
  }
}

.c-eventml__title:hover,
.c-eventml__media-item:hover,
.c-eventml__video-url:hover {
  text-decoration: none;
}

.event-series .collapse-expand-a .block {
  display: none;
}

.collapse-expand-a .block .collapse-expand-cont ul li a.c-eventml__title,
.collapse-expand-a .block .collapse-expand-cont ul li a.c-eventml__video-url,
.collapse-expand-a .block .collapse-expand-cont ul li a.c-eventml__media-item {
  @include font-content();
}

/*Event media links - End*/

/* Bio Publication News and Events Accordion Auto Detect - Start */
.collapse-expand-a .block .collapse-expand-cont.bio-accordion-listing p a.ja {
  @include font-content();
}

.collapse-expand-a
  .block
  .collapse-expand-cont.bio-accordion-listing
  p
  a.zh-cn {
  font-family: "Microsoft YaHei", arial;
}
/* Bio Publication News and Events Accordion Auto Detect - End */

/*Related Services Bio Auto Detect fonts zh-CN & zh-TW CHINESE VERSION - Start */

#globalsearchresult1 > ul#divPeopleListing li.zh-CN div.details h3,
#globalsearchresult1 > ul#divPeopleListing li.zh-TW div.details h3 {
  font-family: "Microsoft YaHei Bold", arial;
  font-size: 18px;
  margin-bottom: 3px;
  text-transform: uppercase;
}

#globalsearchresult1 > ul#divPeopleListing li.zh-CN div.details h3 a:hover,
#globalsearchresult1 > ul#divPeopleListing li.zh-CN div.details h3 a:focus,
#globalsearchresult1 > ul#divPeopleListing li.zh-TW div.details h3 a:hover,
#globalsearchresult1 > ul#divPeopleListing li.zh-TW div.details h3 a:focus {
  color: $color-purple;
}

#globalsearchresult1 > ul#divPeopleListing li.zh-CN div.details p.position-loc,
#globalsearchresult1 > ul#divPeopleListing li.zh-TW div.details p.position-loc {
  color: #000000;
  font-family: "Microsoft YaHei Bold", arial;
  font-size: 14px;
  margin-bottom: 1px;
  text-transform: uppercase;
}

li.zh-CN .mobileBioResult-loc a.zh-CN,
li.zh-TW .mobileBioResult-loc a.zh-TW {
  font-family: "Microsoft YaHei Bold", arial;
  font-size: 14px;
}

li.zh-CN .v-card .mail-id-image a,
li.zh-CN .v-card .bio-mail-id-image a,
li.zh-TW .v-card .mail-id-image a,
li.zh-TW .v-card .bio-mail-id-image a {
  font-family: "Microsoft YaHei Bold", arial;
}

/*Related Services Bio Auto Detect fonts zh-CN CHINESE VERSION - End */

/*Related Services Bio Auto Detect fonts JAPANESE VERSION - Start */
#globalsearchresult1 > ul#divPeopleListing li.ja div.details h3 {
  @include font-heading();
  font-size: 18px;
  margin-bottom: 3px;
  text-transform: uppercase;
}

#globalsearchresult1 > ul#divPeopleListing li.ja div.details p.position-loc {
  @include font-heading();
  color: #000000;
  font-size: 14px;
  margin-bottom: 1px;
  text-transform: uppercase;
}

li.ja .mobileBioResult-loc a.ja {
  @include font-semibold();
  font-size: 14px;
}

li.ja .v-card .mail-id-image a,
li.ja .v-card .bio-mail-id-image a {
  @include font-semibold();
}

/*Related Services Bio Auto Detect fonts JAPANESE VERSION - End */

/*EVENTS, NEWS, PUBLICATION : AUTO DETECT FONT ZH-CN and ZH-TW chinese version search results -- Start*/
/*title futura*/
.search-result-area .block.zh-CN h2 a span {
  font-family: $font-simplified-chinese-content, arial;
  font-weight: 600;
}

.search-result-area .block.zh-TW h2 a span {
  font-family: $font-traditional-chinese-content, arial;
  font-weight: 600;
}

/*description whitneybook*/
.search-result-area .block.zh-CN p span,
.search-result-area .block.zh-CN p {
  font-family: $font-simplified-chinese-content;
}

.search-result-area .block.zh-TW p span,
.search-result-area .block.zh-TW p {
  font-family: $font-traditional-chinese-content;
}
/*date whitneybook*/
.search-result-area .block.zh-CN span {
  font-family: $font-simplified-chinese-content;
}

.search-result-area .block.zh-TW span {
  font-family: $font-traditional-chinese-content;
}
/*EVENTS, NEWS, PUBLICATION : AUTO DETECT FONT ZH-CN and ZH-TW chinese version search results -- End*/

/*EVENTS, NEWS, PUBLICATION : AUTO DETECT FONT JA japanese version search results -- Start*/
/*title futura*/
.search-result-area .block.ja h2 a span {
  @include font-heading();
}

/*description whitneybook*/
.search-result-area .block.ja p span,
.search-result-area .block.ja p {
  @include font-content();
}

/*date whitneybook*/
.search-result-area .block.ja span,
.search-result-area .block.ja span {
  @include font-content();
}

/*EVENTS, NEWS, PUBLICATION : AUTO DETECT FONT ZH-CN and ZH-TW chinese version search results -- End*/

.people-intro-disclaimer {
  font-size: 14px;
  color: #414042;
  line-height: 16px;
  font-style: italic;
  margin-bottom: 20px;
}

.lp-site-only {
  display: none;
}

/* Start Offices, TODO: Move to Offices project after Tiles project is merged into master */

.address-with-map {
  margin-bottom: 17px;
}

.address-with-map .left {
  float: left;
  margin-right: 4%;
  width: 51%;
}

.address-with-map .left .address-block {
  padding: 0px 0px 30px 0px;
}

.address-with-map .left .address-block:last-child {
  margin-top: 8px;
  padding-bottom: 0px;
}

.address-with-map .left .address-block p.phone span,
.address-with-map .left .address-block p.fax span {
  @include font-semibold();
}

.address-with-map .left .address-block p.phone span > a {
  color: #414042;
  font-size: 16px;
  text-decoration: none;
}

.address-with-map .left .address-block p a.direction {
  background: url("../images/arrow-light-grey.png") no-repeat 0 5px;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 15px;
  text-decoration: none;
}

.address-with-map .left .address-block p a.direction:hover,
.address-with-map .left .address-block p a.direction:focus {
  background-position: 0 -43px;
  color: #000;
}

.address-with-map .left .address-block p.fax {
  margin-bottom: 0px;
}

.address-with-map .right {
  float: left;
  width: 45%;
}

#individual-map {
  border: 3px solid #edebeb;
  height: 200px;
  width: 200px;
  position: relative;
}

#mapContainer .breadcrumb-overlay .main-heading h1.map-head {
  padding-bottom: 10px;
}

#mapContainer {
  position: relative;
  height: 609px;
}

.map-overlay {
  display: none;
  background: url(../images/overlay-bg.png) repeat 0px 0px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 609px;
}

.map-box {
  visibility: hidden;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 546px;
  height: 291px;
  background: #ffffff;
  margin-left: -273px;
  margin-top: -135px;
}

.map-box .m-left {
  float: left;
  width: 320px;
}

.map-box .m-right {
  float: left;
  width: 226px;
  height: 291px;
}

.padding-40 {
  padding: 32px 40px 5px 40px;
  background: #ffffff;
}

.map-box .m-left .padding-40 h2 {
  @include font-heading();
  font-size: 28px;
  line-height: 1;
  padding-bottom: 13px;
  text-transform: uppercase;
}

.map-box .m-left .padding-40 p {
  @include font-content();
  font-size: 16px;
  margin-bottom: 8px;
}

.map-box .m-left .padding-40 p span {
  @include font-semibold();
}

.map-box .m-left .padding-40 p a {
  @include font-semibold();
  text-decoration: underline;
}

.map-overlay1 {
  display: block;
  background: url(../images/overlay-bg.png) repeat 0px 0px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 609px;
}

.map-box1 {
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 12;
  width: 546px;
  height: 291px;
  background: #ffffff;
  margin-left: -273px;
  margin-top: -135px;
}

.map-box1 .m-left {
  float: left;
  width: 320px;
}

.map-box1 .m-right {
  float: left;
  width: 226px;
  height: 291px;
}

.padding-40 {
  padding: 32px 40px 5px 40px;
  background: #ffffff;
}

.map-box1 .m-left .padding-40 h2 {
  @include font-heading();
  font-size: 28px;
  line-height: 1;
  padding-bottom: 13px;
  text-transform: uppercase;
}

.map-box1 .m-left .padding-40 h2 a:hover,
.map-box1 .m-left .padding-40 h2 a:focus {
  color: $color-purple;
}

.map-box1 .m-left .padding-40 p {
  @include font-content();
  font-size: 16px;
  margin-bottom: 8px;
}

.map-box1 .m-left .padding-40 p span {
  @include font-semibold();
}

.map-box1 .m-left .padding-40 p span#spManagingPartner {
  @include font-content();
}

.map-box1 .m-left .padding-40 p a {
  @include font-semibold();
  text-decoration: underline;
}

.map-box1 .m-left .padding-40 p#pPhone > a {
  color: #414042;
  font-size: 16px;
  text-decoration: none;
}

#zoom {
  position: absolute;
  top: 550px;
  right: 40px;
  z-index: 9;
  width: 73px;
}

#zoom .zoom-in,
#zoom .zoom-out {
  background-image: url(../images/icon-zoom.png);
  background-repeat: no-repeat;
  float: left;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

#zoom .zoom-in {
  background-position: 0px 0px;
  margin-right: 10px;
}

#zoom .zoom-out {
  background-position: -40px 0px;
}

#zoom .zoom-in:hover,
#zoom .zoom-in:focus {
  background-position: -79px 0px;
}

#zoom .zoom-out:hover,
#zoom .zoom-out:focus {
  background-position: -119px 0px;
}

.close-map,
.close-map1 {
  cursor: pointer;
  float: right;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  top: 10px;
  z-index: 14;
}

#addressMap {
  height: 609px;
  width: 100%;
}

.nearest-add {
  display: none;
  padding-bottom: 25px;
}

.nearest-add p.address-head {
  @include font-heading();
  color: $color-purple;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.nearest-add h2 {
  @include font-heading();
  font-size: 28px;
  line-height: 1;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.nearest-add p {
  @include font-content();
  font-size: 16px;
  margin-bottom: 8px;
}

.nearest-add p a {
  @include font-semibold();
}

.mapcontent .email {
  background-position: 0 8px;
}

@media only screen and (max-width: 768px) {
  #mapContainer .main-heading h1 {
    font-size: 48px;
  }

  #mapContainer .container .col-r {
    width: 100%;
  }

  #mapContainer .breadcrumb-overlay .bread-crumb ul {
    padding-left: 10px;
  }

  #addressMap,
  #mapContainer,
  .map-overlay {
    height: 455px;
  }

  .map-overlay1 {
    height: 455px;
  }

  #zoom {
    right: 13px;
    top: 408px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .map-box {
    top: 56%;
  }
}

@media only screen and (max-width: 500px) {
  .map-overlay,
  .map-overlay1 {
    height: 180px;
  }

  #mapContainer {
    height: 180px;
  }

  #addressMap {
    height: 180px;
  }

  .nearest-add {
    display: block;
  }

  #zoom {
    right: 10px;
    top: 132px;
  }

  #zoom,
  .map-box,
  .map-box1 {
    display: none;
  }
}
/* End Offices */

.collapse-expand-cont__list-item {
  margin-bottom: 10px;
}

.collapse-expand-cont__link {
  color: #414042;
  text-decoration: underline;
}

﻿.ml-sitecore-forms-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ml-sitecore-forms-section:first-child {
    padding-top: 10px
}

.ml-sitecore-forms-section:last-child {
    padding-bottom: 10px;    
}

.ml-sitecore-forms-row {
    display: flex;
    flex-direction: row;
}

.ml-sitecore-forms-row .ml-sitecore-forms-input, .ml-sitecore-forms-row .ml-sitecore-forms-select, .ml-sitecore-forms-row .ml-sitecore-forms-submit {
    @include font-medium();
    width: calc(50% - 24px);
    border: solid 2px #000;
    font-size: 16px;
    padding: 10px 12px;
    text-transform: none;
    box-sizing: border-box;
    color: #000;
}

.ml-sitecore-forms-row .ml-sitecore-forms-radio {
    @include font-medium();
    color: #000;
}

.ml-sitecore-forms-section .ml-sitecore-forms-field {
    display: flex;
    flex-direction: column;
    width: calc(50% - 24px);
    padding: 10px 12px;
    position: relative;
}

.ml-sitecore-forms-row .ml-sitecore-forms-field .ml-sitecore-forms-input, .ml-sitecore-forms-row .ml-sitecore-forms-field .ml-sitecore-forms-select {
    width: 100%;
}

.ml-sitecore-forms-row .ml-sitecore-forms-submit {
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin: 10px 12px;
    background-color: #000;
    color: #fff;
}

.ml-sitecore-forms-row .ml-sitecore-forms-submit:hover {
    background-color: $color-purple;
    color: #fff;
    border: 2px solid $color-purple;
}

.ml-sitecore-forms-row .ml-sitecore-forms-input::placeholder {
    @include font-medium();
    font-size: 16px;
    color: rgb(127, 127, 127);
}

.ml-sitecore-forms-row .ml-sitecore-forms-input.input-validation-error {
    border: 2px solid red;
}

.ml-sitecore-forms-row .ml-sitecore-forms-label {
    @include font-medium();
    font-size: 14px;
    color: #000;
}

.ml-sitecore-forms-form {
    background-color: rgb(215, 215, 215);
}

.ml-sitecore-forms-page-section {
    margin-bottom: 20px;
}

.field-validation-error {
    position: absolute;
    bottom: -6px;
}

.ml-sitecore-forms-label--hide {
    display: none;
}

@media only screen and (max-width : 700px) {
    .ml-sitecore-forms-row {
        flex-direction: column;
    }

    .ml-sitecore-forms-section .ml-sitecore-forms-field {
        width: calc(100% - 48px);
        padding: 10px 24px;
    }

    .ml-sitecore-forms-row .ml-sitecore-forms-submit {
        width: calc(100% - 48px);
        margin: 10px 24px;
    }

    .ml-sitecore-forms-page-section {
        margin-bottom: 30px;
    }
}